import callGraphQL from 'src/graphqlHelper';
import apiErrorHandler from 'src/utils/apiErrorHandler';
import {
  ListInvoicesQuery,
  ListPossibleCustomerAppointmentInvoicesQuery,
  GetInvoiceQuery,
  GetInvoiceByNumberQuery,
  CreateInvoiceMutation,
  VoidInvoiceMutation,
  UpdateInvoiceNoteMutation,
  UpdateInvoiceReferenceMutation,
  UpdateInvoiceItemDescriptionMutation,
  AdminUpdateInvoiceMutation
} from 'src/API';
import {
  listAllInvoicesQuery,
  customGetInvoiceById,
  customGetInvoiceByNumber,
  listRelatedInvoicesQuery,
  customListPatientInvoicesQuery,
  customGetInvoice,
  customlistPossibleCustomerAppointmentInvoices,
  customListAppointmentsInvoices,
  customListShortInvoicesInDateRange
} from './queries';
import {
  createInvoice,
  voidInvoice,
  customUpdateInvoiceNote,
  customUpdateInvoiceReference,
  customUpdateInvoiceItemDescription,
  customUpdateInvoice
} from './mutations';

export const listAllInvoicesAPI = async (
  patientId = '',
  appointmentId = '',
  issueStartTime = new Date(),
  issueEndTime = new Date()
) => {
  try {
    const invoices = appointmentId
      ? await callGraphQL<ListInvoicesQuery>(listAllInvoicesQuery, {
          variables: {
            appointmentId,
            patientId
          }
        })
      : await callGraphQL<ListInvoicesQuery>(listAllInvoicesQuery, {
          variables: {
            patientId,
            issueStartTime,
            issueEndTime
          }
        });
    // console.log(invoices);
    return invoices;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const listAllCustomerInvoicesByIssueDateAPI = async (
  patientId = '',
  issueStartTime = new Date(),
  issueEndTime = new Date()
) => {
  try {
    const invoices = await callGraphQL<ListInvoicesQuery>(
      listAllInvoicesQuery,
      {
        variables: {
          patientId,
          issueStartTime,
          issueEndTime
        }
      }
    );
    // console.log(invoices);
    return invoices;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const listLatestCustomerInvoicesAPI = async (
  patientId = '',
  limit = 0
) => {
  try {
    const invoices = await callGraphQL<ListInvoicesQuery>(
      listAllInvoicesQuery,
      {
        variables: {
          patientId,
          limit,
          orderBy: 'DESC'
        }
      }
    );

    // console.log(invoices);
    return invoices;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const listPossibleCustomerAppointmentInvoicesAPI = async (
  patientId,
  appointmentId
) => {
  try {
    const invoices =
      await callGraphQL<ListPossibleCustomerAppointmentInvoicesQuery>(
        customlistPossibleCustomerAppointmentInvoices,
        {
          variables: {
            customerId: patientId,
            appointmentId
          }
        }
      );
    // console.log(invoices);
    return invoices;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const listAppointmentsInvoicesAPI = async (appointmentIds = []) => {
  try {
    const invoices = await callGraphQL<ListInvoicesQuery>(
      customListAppointmentsInvoices,
      {
        variables: {
          appointmentIds
        }
      }
    );
    // console.log(invoices);
    return invoices;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const listShortInvoicesInDateRangeAPI = async (
  issueStartTime = new Date(),
  issueEndTime = new Date(),
  locationIds = []
) => {
  try {
    const invoices = await callGraphQL<ListInvoicesQuery>(
      customListShortInvoicesInDateRange,
      {
        variables: {
          issueStartTime,
          issueEndTime,
          locationIds
        }
      }
    );
    // console.log(invoices);
    return invoices;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const listInvoicesInDateRangeAPI = async (
  issueStartTime = new Date(),
  issueEndTime = new Date(),
  locationIds = []
) => {
  try {
    const invoices = await callGraphQL<ListInvoicesQuery>(
      listAllInvoicesQuery,
      {
        variables: {
          issueStartTime,
          issueEndTime,
          locationIds
        }
      }
    );
    // console.log(invoices);
    return invoices;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const createInvoiceAPI = async (invoice) => {
  try {
    const newInvoice = await callGraphQL<CreateInvoiceMutation>(createInvoice, {
      variables: {
        input: invoice
      }
    });

    // console.log(newInvoice);
    if (!newInvoice.data.createInvoice) {
      throw new Error();
    }
    return newInvoice;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const voidInvoiceAPI = async (invoice) => {
  try {
    const voidedInvoice = await callGraphQL<VoidInvoiceMutation>(voidInvoice, {
      variables: {
        input: {
          id: invoice.id,
          voidReason: invoice.voidReason
        }
      }
    });
    // console.log(voidedInvoice)
    if (!voidedInvoice.data.voidInvoice) {
      throw new Error();
    }
    return voidedInvoice;
  } catch (error) {
    console.log(error);
    // console.log(apiErrorHandler(error));
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const getInvoiceAPI = async (invoiceId) => {
  try {
    const invoice = await callGraphQL<GetInvoiceQuery>(customGetInvoice, {
      variables: {
        id: invoiceId
      }
    });

    // console.log(invoice);
    if (!invoice.data.getInvoice) {
      throw new Error();
    }
    return invoice;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const getInvoiceByIdAPI = async (invoiceId) => {
  try {
    const invoice = await callGraphQL<GetInvoiceQuery>(customGetInvoiceById, {
      variables: {
        id: invoiceId
      }
    });
    return invoice;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const getInvoiceByNumberAPI = async (invoiceNumber) => {
  try {
    const invoice = await callGraphQL<GetInvoiceByNumberQuery>(
      customGetInvoiceByNumber,
      {
        variables: {
          invoiceNumber: invoiceNumber
        }
      }
    );
    return invoice;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const listCorporateInvoicesAPI = async (input) => {
  try {
    const invoices = await callGraphQL<ListInvoicesQuery>(
      listAllInvoicesQuery,
      {
        variables: input
      }
    );
    // console.log(invoices);
    return invoices;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const updateInvoiceNoteAPI = async ({ invoiceId, note }) => {
  try {
    const invoice = await callGraphQL<UpdateInvoiceNoteMutation>(
      customUpdateInvoiceNote,
      {
        variables: {
          invoiceId,
          note
        }
      }
    );
    return invoice;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const updateInvoiceReferenceAPI = async ({ invoiceId, reference }) => {
  try {
    const invoice = await callGraphQL<UpdateInvoiceReferenceMutation>(
      customUpdateInvoiceReference,
      {
        variables: {
          invoiceId,
          reference
        }
      }
    );
    return invoice;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const listRelatedInvoicesAPI = async (appointmentId) => {
  try {
    const invoices = await callGraphQL<ListInvoicesQuery>(
      listRelatedInvoicesQuery,
      {
        variables: {
          appointmentId
        }
      }
    );
    // console.log(invoices);
    return invoices;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const updateInvoiceItemDescriptionAPI = async ({
  invoiceId,
  invoiceItemId,
  name,
  description
}) => {
  try {
    const invoice = await callGraphQL<UpdateInvoiceItemDescriptionMutation>(
      customUpdateInvoiceItemDescription,
      {
        variables: {
          input: { id: invoiceItemId, invoiceId, name, description }
        }
      }
    );
    return invoice;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const updateInvoiceAPI = async ({
  invoiceId,
  providerId,
  reference,
  note
}) => {
  try {
    const invoice = await callGraphQL<AdminUpdateInvoiceMutation>(
      customUpdateInvoice,
      {
        variables: {
          input: {
            id: invoiceId,
            providerId: providerId,
            reference: reference,
            note: note
          }
        }
      }
    );
    return invoice;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};
