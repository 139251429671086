/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getIamUser = /* GraphQL */ `
  query GetIamUser($id: ID!) {
    getIamUser(id: $id) {
      id
      username
      description
      roles {
        nextToken
      }
      policies {
        nextToken
      }
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const listIamUsers = /* GraphQL */ `
  query ListIamUsers(
    $filter: ModelIamUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listIamUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        username
        description
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      nextToken
    }
  }
`;
export const getIamRole = /* GraphQL */ `
  query GetIamRole($id: ID!) {
    getIamRole(id: $id) {
      id
      name
      description
      users {
        nextToken
      }
      policies {
        nextToken
      }
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const listIamRoles = /* GraphQL */ `
  query ListIamRoles(
    $filter: ModelIamRoleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listIamRoles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      nextToken
    }
  }
`;
export const getIamPolicy = /* GraphQL */ `
  query GetIamPolicy($id: ID!) {
    getIamPolicy(id: $id) {
      id
      name
      description
      documents {
        effect
        resources
        actions
      }
      roles {
        nextToken
      }
      users {
        nextToken
      }
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const listIamPolicies = /* GraphQL */ `
  query ListIamPolicies(
    $filter: ModelIamPolicyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listIamPolicies(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      nextToken
    }
  }
`;
export const getIamUserRoles = /* GraphQL */ `
  query GetIamUserRoles($id: ID!) {
    getIamUserRoles(id: $id) {
      id
      iamUserId
      iamRoleId
      iamUser {
        id
        username
        description
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      iamRole {
        id
        name
        description
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
    }
  }
`;
export const listIamUserRoles = /* GraphQL */ `
  query ListIamUserRoles(
    $filter: ModelIamUserRolesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listIamUserRoles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        iamUserId
        iamRoleId
      }
      nextToken
    }
  }
`;
export const getIamUserPolicies = /* GraphQL */ `
  query GetIamUserPolicies($id: ID!) {
    getIamUserPolicies(id: $id) {
      id
      iamUserId
      iamPolicyId
      iamUser {
        id
        username
        description
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      iamPolicy {
        id
        name
        description
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
    }
  }
`;
export const listIamUserPolicies = /* GraphQL */ `
  query ListIamUserPolicies(
    $filter: ModelIamUserPoliciesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listIamUserPolicies(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        iamUserId
        iamPolicyId
      }
      nextToken
    }
  }
`;
export const getIamRolePolicies = /* GraphQL */ `
  query GetIamRolePolicies($id: ID!) {
    getIamRolePolicies(id: $id) {
      id
      iamRoleId
      iamPolicyId
      iamRole {
        id
        name
        description
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      iamPolicy {
        id
        name
        description
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
    }
  }
`;
export const listIamRolePolicies = /* GraphQL */ `
  query ListIamRolePolicies(
    $filter: ModelIamRolePoliciesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listIamRolePolicies(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        iamRoleId
        iamPolicyId
      }
      nextToken
    }
  }
`;
export const getMyRoles = /* GraphQL */ `
  query GetMyRoles {
    getMyRoles {
      id
      name
      description
      users {
        nextToken
      }
      policies {
        nextToken
      }
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const getPatient = /* GraphQL */ `
  query GetPatient($id: ID!) {
    getPatient(id: $id) {
      id
      fullName
      isInitialized
      location {
        id
        name
        code
        addressStreetAddress
        addressUnitNo
        addressCountry
        addressPostalCode
        taxStatus
        registeredName
        registrationNumber
        operatingHours
        telephones
        whatsapp
        emails
        isActive
        stripeAccountId
        stripeLocationId
        stripeTerminalIds
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      preferredProviderIds
      preferredProviders {
        id
        username
        type
        preferredName
        firstName
        lastName
        middleName
        jobTitle
        profilePicture
        description
        email
        mobile
        primaryLocationId
        currentLocationId
        allowedLocationIds
        timezone
        country
        createdAt
        createdBy
        updatedAt
        updatedBy
        identityNumber
        identityType
        onboardDate
        resignationDate
        isActive
        viewOnlyMemberCalendars
        registrationAuthority
        registrationNumber
        discipline
        qualifications
      }
      piiProfile {
        id
        fullName
        preferredName
        identityNumber
        identityType
        birthday
        maritalStatus
        title
        gender
        nationality
        mobileCountryCode
        mobile
        email
        addressStreetAddress
        addressUnitNo
        addressCountry
        addressPostalCode
        telephone2
        telephone3
        occupation
        internalNote
        employer
        nextOfKin
        stripeCustomerId
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      medicalProfile {
        id
        noKnownDrugAllergy
        noKnownFoodAllergy
        noKnownOtherAllergy
        drugAllergies
        foodAllergies
        otherAllergies
        vaccines
        insurance
        subsidySchemes
        medicalConditions
        noKnownMedicalConditions
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      marketingProfile {
        id
        referredBy
        referralSource
        referralCategory
        consent
        membershipTier
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      strictlyProviders
      customerShortCode
      profilePicture
      quickbooksId
      extraData {
        completedAppointmentsCount
      }
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const getCustomerByShortCode = /* GraphQL */ `
  query GetCustomerByShortCode($shortCode: String!) {
    getCustomerByShortCode(shortCode: $shortCode) {
      id
      fullName
      isInitialized
      location {
        id
        name
        code
        addressStreetAddress
        addressUnitNo
        addressCountry
        addressPostalCode
        taxStatus
        registeredName
        registrationNumber
        operatingHours
        telephones
        whatsapp
        emails
        isActive
        stripeAccountId
        stripeLocationId
        stripeTerminalIds
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      preferredProviderIds
      preferredProviders {
        id
        username
        type
        preferredName
        firstName
        lastName
        middleName
        jobTitle
        profilePicture
        description
        email
        mobile
        primaryLocationId
        currentLocationId
        allowedLocationIds
        timezone
        country
        createdAt
        createdBy
        updatedAt
        updatedBy
        identityNumber
        identityType
        onboardDate
        resignationDate
        isActive
        viewOnlyMemberCalendars
        registrationAuthority
        registrationNumber
        discipline
        qualifications
      }
      piiProfile {
        id
        fullName
        preferredName
        identityNumber
        identityType
        birthday
        maritalStatus
        title
        gender
        nationality
        mobileCountryCode
        mobile
        email
        addressStreetAddress
        addressUnitNo
        addressCountry
        addressPostalCode
        telephone2
        telephone3
        occupation
        internalNote
        employer
        nextOfKin
        stripeCustomerId
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      medicalProfile {
        id
        noKnownDrugAllergy
        noKnownFoodAllergy
        noKnownOtherAllergy
        drugAllergies
        foodAllergies
        otherAllergies
        vaccines
        insurance
        subsidySchemes
        medicalConditions
        noKnownMedicalConditions
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      marketingProfile {
        id
        referredBy
        referralSource
        referralCategory
        consent
        membershipTier
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      strictlyProviders
      customerShortCode
      profilePicture
      quickbooksId
      extraData {
        completedAppointmentsCount
      }
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const listPatientPiiProfiles = /* GraphQL */ `
  query ListPatientPiiProfiles(
    $searchTerm: String
    $locationId: ID
    $limit: Int
    $nextToken: String
  ) {
    listPatientPiiProfiles(
      searchTerm: $searchTerm
      locationId: $locationId
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        fullName
        preferredName
        identityNumber
        identityType
        birthday
        maritalStatus
        title
        gender
        nationality
        mobileCountryCode
        mobile
        email
        addressStreetAddress
        addressUnitNo
        addressCountry
        addressPostalCode
        telephone2
        telephone3
        occupation
        internalNote
        employer
        nextOfKin
        stripeCustomerId
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      nextToken
    }
  }
`;
export const getOfficeLocation = /* GraphQL */ `
  query GetOfficeLocation($id: ID!) {
    getOfficeLocation(id: $id) {
      id
      name
      code
      addressStreetAddress
      addressUnitNo
      addressCountry
      addressPostalCode
      billingAddresses {
        streetAddress
        unitNo
        country
        postalCode
        startDate
        endDate
      }
      taxStatus
      registeredName
      registrationNumber
      operatingHours
      telephones
      whatsapp
      emails
      isActive
      quickbooks {
        isEnabled
      }
      stripeAccountId
      stripeLocationId
      stripeTerminalIds
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const listOfficeLocations = /* GraphQL */ `
  query ListOfficeLocations {
    listOfficeLocations {
      id
      name
      code
      addressStreetAddress
      addressUnitNo
      addressCountry
      addressPostalCode
      billingAddresses {
        streetAddress
        unitNo
        country
        postalCode
        startDate
        endDate
      }
      taxStatus
      registeredName
      registrationNumber
      operatingHours
      telephones
      whatsapp
      emails
      isActive
      quickbooks {
        isEnabled
      }
      stripeAccountId
      stripeLocationId
      stripeTerminalIds
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const getService = /* GraphQL */ `
  query GetService($id: ID!, $isActive: Boolean) {
    getService(id: $id, isActive: $isActive) {
      id
      locationId
      practitionerId
      location {
        id
        name
        code
        addressStreetAddress
        addressUnitNo
        addressCountry
        addressPostalCode
        taxStatus
        registeredName
        registrationNumber
        operatingHours
        telephones
        whatsapp
        emails
        isActive
        stripeAccountId
        stripeLocationId
        stripeTerminalIds
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      practitioner {
        id
        username
        type
        preferredName
        firstName
        lastName
        middleName
        jobTitle
        profilePicture
        description
        email
        mobile
        primaryLocationId
        currentLocationId
        allowedLocationIds
        timezone
        country
        createdAt
        createdBy
        updatedAt
        updatedBy
        identityNumber
        identityType
        onboardDate
        resignationDate
        isActive
        viewOnlyMemberCalendars
        registrationAuthority
        registrationNumber
        discipline
        qualifications
      }
      name
      code
      description
      onlineBookingDescription
      category
      duration
      price {
        amount
        currency
        precision
        scale
      }
      quickbooksId
      workingHours
      onlineBookingName
      onlineBookingCategory
      isActive
      promoCodes {
        id
        code
        type
        name
        description
        applicableLocationIds
        applicableServiceIds
        applicableClassServiceIds
        applicableInventoryIds
        startTime
        endTime
        isActive
        maxTotalRedemptions
        maxPerCustomerRedemptions
        currentRedemptions
        ruleTemplateType
        ruleTemplateVariables
        rules
        isStackable
        stackingPriorityLevel
        contactId
        corporateId
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const listServices = /* GraphQL */ `
  query ListServices(
    $locationId: ID
    $practitionerId: ID
    $serviceName: String
    $isActive: Boolean
  ) {
    listServices(
      locationId: $locationId
      practitionerId: $practitionerId
      serviceName: $serviceName
      isActive: $isActive
    ) {
      id
      locationId
      practitionerId
      location {
        id
        name
        code
        addressStreetAddress
        addressUnitNo
        addressCountry
        addressPostalCode
        taxStatus
        registeredName
        registrationNumber
        operatingHours
        telephones
        whatsapp
        emails
        isActive
        stripeAccountId
        stripeLocationId
        stripeTerminalIds
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      practitioner {
        id
        username
        type
        preferredName
        firstName
        lastName
        middleName
        jobTitle
        profilePicture
        description
        email
        mobile
        primaryLocationId
        currentLocationId
        allowedLocationIds
        timezone
        country
        createdAt
        createdBy
        updatedAt
        updatedBy
        identityNumber
        identityType
        onboardDate
        resignationDate
        isActive
        viewOnlyMemberCalendars
        registrationAuthority
        registrationNumber
        discipline
        qualifications
      }
      name
      code
      description
      onlineBookingDescription
      category
      duration
      price {
        amount
        currency
        precision
        scale
      }
      quickbooksId
      workingHours
      onlineBookingName
      onlineBookingCategory
      isActive
      promoCodes {
        id
        code
        type
        name
        description
        applicableLocationIds
        applicableServiceIds
        applicableClassServiceIds
        applicableInventoryIds
        startTime
        endTime
        isActive
        maxTotalRedemptions
        maxPerCustomerRedemptions
        currentRedemptions
        ruleTemplateType
        ruleTemplateVariables
        rules
        isStackable
        stackingPriorityLevel
        contactId
        corporateId
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const getClassService = /* GraphQL */ `
  query GetClassService($id: ID!, $isActive: Boolean) {
    getClassService(id: $id, isActive: $isActive) {
      id
      locationId
      practitionerId
      location {
        id
        name
        code
        addressStreetAddress
        addressUnitNo
        addressCountry
        addressPostalCode
        taxStatus
        registeredName
        registrationNumber
        operatingHours
        telephones
        whatsapp
        emails
        isActive
        stripeAccountId
        stripeLocationId
        stripeTerminalIds
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      practitioner {
        id
        username
        type
        preferredName
        firstName
        lastName
        middleName
        jobTitle
        profilePicture
        description
        email
        mobile
        primaryLocationId
        currentLocationId
        allowedLocationIds
        timezone
        country
        createdAt
        createdBy
        updatedAt
        updatedBy
        identityNumber
        identityType
        onboardDate
        resignationDate
        isActive
        viewOnlyMemberCalendars
        registrationAuthority
        registrationNumber
        discipline
        qualifications
      }
      name
      code
      description
      onlineBookingDescription
      category
      duration
      price {
        amount
        currency
        precision
        scale
      }
      maxParticipants
      onlineBookingName
      onlineBookingCategory
      isActive
      promoCodes {
        id
        code
        type
        name
        description
        applicableLocationIds
        applicableServiceIds
        applicableClassServiceIds
        applicableInventoryIds
        startTime
        endTime
        isActive
        maxTotalRedemptions
        maxPerCustomerRedemptions
        currentRedemptions
        ruleTemplateType
        ruleTemplateVariables
        rules
        isStackable
        stackingPriorityLevel
        contactId
        corporateId
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const listClassServices = /* GraphQL */ `
  query ListClassServices(
    $locationId: ID
    $practitionerId: ID
    $classServiceName: String
    $isActive: Boolean
  ) {
    listClassServices(
      locationId: $locationId
      practitionerId: $practitionerId
      classServiceName: $classServiceName
      isActive: $isActive
    ) {
      id
      locationId
      practitionerId
      location {
        id
        name
        code
        addressStreetAddress
        addressUnitNo
        addressCountry
        addressPostalCode
        taxStatus
        registeredName
        registrationNumber
        operatingHours
        telephones
        whatsapp
        emails
        isActive
        stripeAccountId
        stripeLocationId
        stripeTerminalIds
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      practitioner {
        id
        username
        type
        preferredName
        firstName
        lastName
        middleName
        jobTitle
        profilePicture
        description
        email
        mobile
        primaryLocationId
        currentLocationId
        allowedLocationIds
        timezone
        country
        createdAt
        createdBy
        updatedAt
        updatedBy
        identityNumber
        identityType
        onboardDate
        resignationDate
        isActive
        viewOnlyMemberCalendars
        registrationAuthority
        registrationNumber
        discipline
        qualifications
      }
      name
      code
      description
      onlineBookingDescription
      category
      duration
      price {
        amount
        currency
        precision
        scale
      }
      maxParticipants
      onlineBookingName
      onlineBookingCategory
      isActive
      promoCodes {
        id
        code
        type
        name
        description
        applicableLocationIds
        applicableServiceIds
        applicableClassServiceIds
        applicableInventoryIds
        startTime
        endTime
        isActive
        maxTotalRedemptions
        maxPerCustomerRedemptions
        currentRedemptions
        ruleTemplateType
        ruleTemplateVariables
        rules
        isStackable
        stackingPriorityLevel
        contactId
        corporateId
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const getPractitioner = /* GraphQL */ `
  query GetPractitioner($id: ID!) {
    getPractitioner(id: $id) {
      id
      username
      type
      preferredName
      firstName
      lastName
      middleName
      jobTitle
      profilePicture
      description
      email
      mobile
      primaryLocationId
      primaryLocation {
        id
        name
        code
        addressStreetAddress
        addressUnitNo
        addressCountry
        addressPostalCode
        taxStatus
        registeredName
        registrationNumber
        operatingHours
        telephones
        whatsapp
        emails
        isActive
        stripeAccountId
        stripeLocationId
        stripeTerminalIds
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      currentLocationId
      currentLocation {
        id
        name
        code
        addressStreetAddress
        addressUnitNo
        addressCountry
        addressPostalCode
        taxStatus
        registeredName
        registrationNumber
        operatingHours
        telephones
        whatsapp
        emails
        isActive
        stripeAccountId
        stripeLocationId
        stripeTerminalIds
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      allowedLocationIds
      allowedLocations {
        id
        name
        code
        addressStreetAddress
        addressUnitNo
        addressCountry
        addressPostalCode
        taxStatus
        registeredName
        registrationNumber
        operatingHours
        telephones
        whatsapp
        emails
        isActive
        stripeAccountId
        stripeLocationId
        stripeTerminalIds
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      roles {
        id
        name
        description
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      timezone
      country
      createdAt
      createdBy
      updatedAt
      updatedBy
      identityNumber
      identityType
      onboardDate
      resignationDate
      isActive
      viewOnlyMemberCalendars
      registrationAuthority
      registrationNumber
      discipline
      qualifications
      services {
        id
        locationId
        practitionerId
        name
        code
        description
        onlineBookingDescription
        category
        duration
        quickbooksId
        workingHours
        onlineBookingName
        onlineBookingCategory
        isActive
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      locations {
        id
        name
        code
        addressStreetAddress
        addressUnitNo
        addressCountry
        addressPostalCode
        taxStatus
        registeredName
        registrationNumber
        operatingHours
        telephones
        whatsapp
        emails
        isActive
        stripeAccountId
        stripeLocationId
        stripeTerminalIds
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
    }
  }
`;
export const listPractitioners = /* GraphQL */ `
  query ListPractitioners($locationIds: [ID], $serviceName: String) {
    listPractitioners(locationIds: $locationIds, serviceName: $serviceName) {
      id
      username
      type
      preferredName
      firstName
      lastName
      middleName
      jobTitle
      profilePicture
      description
      email
      mobile
      primaryLocationId
      primaryLocation {
        id
        name
        code
        addressStreetAddress
        addressUnitNo
        addressCountry
        addressPostalCode
        taxStatus
        registeredName
        registrationNumber
        operatingHours
        telephones
        whatsapp
        emails
        isActive
        stripeAccountId
        stripeLocationId
        stripeTerminalIds
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      currentLocationId
      currentLocation {
        id
        name
        code
        addressStreetAddress
        addressUnitNo
        addressCountry
        addressPostalCode
        taxStatus
        registeredName
        registrationNumber
        operatingHours
        telephones
        whatsapp
        emails
        isActive
        stripeAccountId
        stripeLocationId
        stripeTerminalIds
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      allowedLocationIds
      allowedLocations {
        id
        name
        code
        addressStreetAddress
        addressUnitNo
        addressCountry
        addressPostalCode
        taxStatus
        registeredName
        registrationNumber
        operatingHours
        telephones
        whatsapp
        emails
        isActive
        stripeAccountId
        stripeLocationId
        stripeTerminalIds
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      roles {
        id
        name
        description
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      timezone
      country
      createdAt
      createdBy
      updatedAt
      updatedBy
      identityNumber
      identityType
      onboardDate
      resignationDate
      isActive
      viewOnlyMemberCalendars
      registrationAuthority
      registrationNumber
      discipline
      qualifications
      services {
        id
        locationId
        practitionerId
        name
        code
        description
        onlineBookingDescription
        category
        duration
        quickbooksId
        workingHours
        onlineBookingName
        onlineBookingCategory
        isActive
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      locations {
        id
        name
        code
        addressStreetAddress
        addressUnitNo
        addressCountry
        addressPostalCode
        taxStatus
        registeredName
        registrationNumber
        operatingHours
        telephones
        whatsapp
        emails
        isActive
        stripeAccountId
        stripeLocationId
        stripeTerminalIds
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers($locationIds: [ID!], $type: String) {
    listUsers(locationIds: $locationIds, type: $type) {
      id
      username
      type
      preferredName
      firstName
      lastName
      middleName
      jobTitle
      profilePicture
      description
      email
      mobile
      primaryLocationId
      primaryLocation {
        id
        name
        code
        addressStreetAddress
        addressUnitNo
        addressCountry
        addressPostalCode
        taxStatus
        registeredName
        registrationNumber
        operatingHours
        telephones
        whatsapp
        emails
        isActive
        stripeAccountId
        stripeLocationId
        stripeTerminalIds
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      currentLocationId
      currentLocation {
        id
        name
        code
        addressStreetAddress
        addressUnitNo
        addressCountry
        addressPostalCode
        taxStatus
        registeredName
        registrationNumber
        operatingHours
        telephones
        whatsapp
        emails
        isActive
        stripeAccountId
        stripeLocationId
        stripeTerminalIds
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      allowedLocationIds
      allowedLocations {
        id
        name
        code
        addressStreetAddress
        addressUnitNo
        addressCountry
        addressPostalCode
        taxStatus
        registeredName
        registrationNumber
        operatingHours
        telephones
        whatsapp
        emails
        isActive
        stripeAccountId
        stripeLocationId
        stripeTerminalIds
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      roles {
        id
        name
        description
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      timezone
      country
      createdAt
      createdBy
      updatedAt
      updatedBy
      identityNumber
      identityType
      onboardDate
      resignationDate
      isActive
      viewOnlyMemberCalendars
      registrationAuthority
      registrationNumber
      discipline
      qualifications
      services {
        id
        locationId
        practitionerId
        name
        code
        description
        onlineBookingDescription
        category
        duration
        quickbooksId
        workingHours
        onlineBookingName
        onlineBookingCategory
        isActive
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      locations {
        id
        name
        code
        addressStreetAddress
        addressUnitNo
        addressCountry
        addressPostalCode
        taxStatus
        registeredName
        registrationNumber
        operatingHours
        telephones
        whatsapp
        emails
        isActive
        stripeAccountId
        stripeLocationId
        stripeTerminalIds
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
    }
  }
`;
export const getAppointment = /* GraphQL */ `
  query GetAppointment($id: ID!) {
    getAppointment(id: $id) {
      id
      patientId
      patient {
        id
        fullName
        isInitialized
        preferredProviderIds
        strictlyProviders
        customerShortCode
        profilePicture
        quickbooksId
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      serviceId
      service {
        id
        locationId
        practitionerId
        name
        code
        description
        onlineBookingDescription
        category
        duration
        quickbooksId
        workingHours
        onlineBookingName
        onlineBookingCategory
        isActive
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      locationId
      location {
        id
        name
        code
        addressStreetAddress
        addressUnitNo
        addressCountry
        addressPostalCode
        taxStatus
        registeredName
        registrationNumber
        operatingHours
        telephones
        whatsapp
        emails
        isActive
        stripeAccountId
        stripeLocationId
        stripeTerminalIds
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      practitionerId
      flowActivityId
      practitioner {
        id
        username
        type
        preferredName
        firstName
        lastName
        middleName
        jobTitle
        profilePicture
        description
        email
        mobile
        primaryLocationId
        currentLocationId
        allowedLocationIds
        timezone
        country
        createdAt
        createdBy
        updatedAt
        updatedBy
        identityNumber
        identityType
        onboardDate
        resignationDate
        isActive
        viewOnlyMemberCalendars
        registrationAuthority
        registrationNumber
        discipline
        qualifications
      }
      appointmentDate
      appointmentStartTime
      appointmentEndTime
      appointmentCheckInTime
      appointmentCompleteTime
      type
      status
      emailReminderSent
      emailReminderAt
      smsReminderSent
      smsReminderSentAt
      appointmentNote
      invoices {
        id
        type
        patientId
        appointmentId
        practitionerId
        locationId
        corporateId
        invoiceNumber
        issueDate
        dueDate
        reference
        note
        voidedAt
        voidedBy
        voidedReason
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      confirmedAt
      confirmedBy
      cancellationAt
      cancellationBy
      cancellationNote
      classAppointmentId
      classAppointment {
        id
        classServiceId
        practitionerId
        locationId
        classAppointmentDate
        classAppointmentStartTime
        classAppointmentEndTime
        classPublicNotes
        status
        recurId
        maxParticipants
        patientIds
        classNotes
        isBookingAllowed
        recurFrequency
        recurStartDate
        recurEndDate
        recurInstances
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      classServiceId
      passId
      patientPassId
      passRedemptionId
      planId
      planMilestone
      onlineBookingTransactionId
      onlineBookingPageId
      onlineBookingCustomFields
      promoCodes {
        id
        code
        type
        name
        description
        applicableLocationIds
        applicableServiceIds
        applicableClassServiceIds
        applicableInventoryIds
        startTime
        endTime
        isActive
        maxTotalRedemptions
        maxPerCustomerRedemptions
        currentRedemptions
        ruleTemplateType
        ruleTemplateVariables
        rules
        isStackable
        stackingPriorityLevel
        contactId
        corporateId
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      createdAt
      createdBy
      updatedAt
      updatedBy
      voidedAt
      voidedBy
      voidedReason
    }
  }
`;
export const listAppointments = /* GraphQL */ `
  query ListAppointments(
    $appointmentStartDate: AWSDate
    $appointmentEndDate: AWSDate
    $practitionerIds: [ID!]
    $patientIds: [ID!]
    $locationIds: [ID!]
    $serviceIds: [ID!]
    $type: String
    $status: String
    $includeClasses: Boolean
    $classAppointmentIds: [ID!]
  ) {
    listAppointments(
      appointmentStartDate: $appointmentStartDate
      appointmentEndDate: $appointmentEndDate
      practitionerIds: $practitionerIds
      patientIds: $patientIds
      locationIds: $locationIds
      serviceIds: $serviceIds
      type: $type
      status: $status
      includeClasses: $includeClasses
      classAppointmentIds: $classAppointmentIds
    ) {
      id
      patientId
      patient {
        id
        fullName
        isInitialized
        preferredProviderIds
        strictlyProviders
        customerShortCode
        profilePicture
        quickbooksId
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      serviceId
      service {
        id
        locationId
        practitionerId
        name
        code
        description
        onlineBookingDescription
        category
        duration
        quickbooksId
        workingHours
        onlineBookingName
        onlineBookingCategory
        isActive
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      locationId
      location {
        id
        name
        code
        addressStreetAddress
        addressUnitNo
        addressCountry
        addressPostalCode
        taxStatus
        registeredName
        registrationNumber
        operatingHours
        telephones
        whatsapp
        emails
        isActive
        stripeAccountId
        stripeLocationId
        stripeTerminalIds
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      practitionerId
      flowActivityId
      practitioner {
        id
        username
        type
        preferredName
        firstName
        lastName
        middleName
        jobTitle
        profilePicture
        description
        email
        mobile
        primaryLocationId
        currentLocationId
        allowedLocationIds
        timezone
        country
        createdAt
        createdBy
        updatedAt
        updatedBy
        identityNumber
        identityType
        onboardDate
        resignationDate
        isActive
        viewOnlyMemberCalendars
        registrationAuthority
        registrationNumber
        discipline
        qualifications
      }
      appointmentDate
      appointmentStartTime
      appointmentEndTime
      appointmentCheckInTime
      appointmentCompleteTime
      type
      status
      emailReminderSent
      emailReminderAt
      smsReminderSent
      smsReminderSentAt
      appointmentNote
      invoices {
        id
        type
        patientId
        appointmentId
        practitionerId
        locationId
        corporateId
        invoiceNumber
        issueDate
        dueDate
        reference
        note
        voidedAt
        voidedBy
        voidedReason
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      confirmedAt
      confirmedBy
      cancellationAt
      cancellationBy
      cancellationNote
      classAppointmentId
      classAppointment {
        id
        classServiceId
        practitionerId
        locationId
        classAppointmentDate
        classAppointmentStartTime
        classAppointmentEndTime
        classPublicNotes
        status
        recurId
        maxParticipants
        patientIds
        classNotes
        isBookingAllowed
        recurFrequency
        recurStartDate
        recurEndDate
        recurInstances
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      classServiceId
      passId
      patientPassId
      passRedemptionId
      planId
      planMilestone
      onlineBookingTransactionId
      onlineBookingPageId
      onlineBookingCustomFields
      promoCodes {
        id
        code
        type
        name
        description
        applicableLocationIds
        applicableServiceIds
        applicableClassServiceIds
        applicableInventoryIds
        startTime
        endTime
        isActive
        maxTotalRedemptions
        maxPerCustomerRedemptions
        currentRedemptions
        ruleTemplateType
        ruleTemplateVariables
        rules
        isStackable
        stackingPriorityLevel
        contactId
        corporateId
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      createdAt
      createdBy
      updatedAt
      updatedBy
      voidedAt
      voidedBy
      voidedReason
    }
  }
`;
export const listFreeAppointmentBookingSlots = /* GraphQL */ `
  query ListFreeAppointmentBookingSlots(
    $startDate: AWSDate!
    $endDate: AWSDate!
    $serviceIds: [ID!]
    $classServiceIds: [ID!]
    $tenantId: String
    $incrementInterval: Int
  ) {
    listFreeAppointmentBookingSlots(
      startDate: $startDate
      endDate: $endDate
      serviceIds: $serviceIds
      classServiceIds: $classServiceIds
      tenantId: $tenantId
      incrementInterval: $incrementInterval
    ) {
      service {
        id
        locationId
        practitionerId
        name
        code
        description
        onlineBookingDescription
        category
        duration
        quickbooksId
        workingHours
        onlineBookingName
        onlineBookingCategory
        isActive
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      startTime
      endTime
    }
  }
`;
export const listFreeAppointmentRescheduleSlots = /* GraphQL */ `
  query ListFreeAppointmentRescheduleSlots(
    $startDate: AWSDate!
    $endDate: AWSDate!
    $appointmentId: ID!
    $tenantId: ID
    $incrementInterval: Int
  ) {
    listFreeAppointmentRescheduleSlots(
      startDate: $startDate
      endDate: $endDate
      appointmentId: $appointmentId
      tenantId: $tenantId
      incrementInterval: $incrementInterval
    ) {
      appointment {
        id
        patientId
        serviceId
        locationId
        practitionerId
        flowActivityId
        appointmentDate
        appointmentStartTime
        appointmentEndTime
        appointmentCheckInTime
        appointmentCompleteTime
        type
        status
        emailReminderSent
        emailReminderAt
        smsReminderSent
        smsReminderSentAt
        appointmentNote
        confirmedAt
        confirmedBy
        cancellationAt
        cancellationBy
        cancellationNote
        classAppointmentId
        classServiceId
        passId
        patientPassId
        passRedemptionId
        planId
        planMilestone
        onlineBookingTransactionId
        onlineBookingPageId
        onlineBookingCustomFields
        createdAt
        createdBy
        updatedAt
        updatedBy
        voidedAt
        voidedBy
        voidedReason
      }
      service {
        id
        locationId
        practitionerId
        name
        code
        description
        onlineBookingDescription
        category
        duration
        quickbooksId
        workingHours
        onlineBookingName
        onlineBookingCategory
        isActive
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      provider {
        id
        username
        type
        preferredName
        firstName
        lastName
        middleName
        jobTitle
        profilePicture
        description
        email
        mobile
        primaryLocationId
        currentLocationId
        allowedLocationIds
        timezone
        country
        createdAt
        createdBy
        updatedAt
        updatedBy
        identityNumber
        identityType
        onboardDate
        resignationDate
        isActive
        viewOnlyMemberCalendars
        registrationAuthority
        registrationNumber
        discipline
        qualifications
      }
      slots {
        startTime
        endTime
      }
    }
  }
`;
export const getPatientNote = /* GraphQL */ `
  query GetPatientNote($id: ID!) {
    getPatientNote(id: $id) {
      id
      patientId
      patient {
        id
        fullName
        isInitialized
        preferredProviderIds
        strictlyProviders
        customerShortCode
        profilePicture
        quickbooksId
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      appointmentId
      appointment {
        id
        patientId
        serviceId
        locationId
        practitionerId
        flowActivityId
        appointmentDate
        appointmentStartTime
        appointmentEndTime
        appointmentCheckInTime
        appointmentCompleteTime
        type
        status
        emailReminderSent
        emailReminderAt
        smsReminderSent
        smsReminderSentAt
        appointmentNote
        confirmedAt
        confirmedBy
        cancellationAt
        cancellationBy
        cancellationNote
        classAppointmentId
        classServiceId
        passId
        patientPassId
        passRedemptionId
        planId
        planMilestone
        onlineBookingTransactionId
        onlineBookingPageId
        onlineBookingCustomFields
        createdAt
        createdBy
        updatedAt
        updatedBy
        voidedAt
        voidedBy
        voidedReason
      }
      type
      title
      content
      isPublished
      timezone
      noteFolder
      attachments
      publishedAt
      notePhotos {
        documentId
        bucketName
        key
      }
      voidedAt
      voidedBy
      voidedReason
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const listPatientNotes = /* GraphQL */ `
  query ListPatientNotes(
    $patientId: ID!
    $appointmentId: ID
    $createdStartTime: AWSDateTime
    $createdEndTime: AWSDateTime
    $isNewestFirst: Boolean
    $type: String
    $limit: Int
  ) {
    listPatientNotes(
      patientId: $patientId
      appointmentId: $appointmentId
      createdStartTime: $createdStartTime
      createdEndTime: $createdEndTime
      isNewestFirst: $isNewestFirst
      type: $type
      limit: $limit
    ) {
      id
      patientId
      patient {
        id
        fullName
        isInitialized
        preferredProviderIds
        strictlyProviders
        customerShortCode
        profilePicture
        quickbooksId
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      appointmentId
      appointment {
        id
        patientId
        serviceId
        locationId
        practitionerId
        flowActivityId
        appointmentDate
        appointmentStartTime
        appointmentEndTime
        appointmentCheckInTime
        appointmentCompleteTime
        type
        status
        emailReminderSent
        emailReminderAt
        smsReminderSent
        smsReminderSentAt
        appointmentNote
        confirmedAt
        confirmedBy
        cancellationAt
        cancellationBy
        cancellationNote
        classAppointmentId
        classServiceId
        passId
        patientPassId
        passRedemptionId
        planId
        planMilestone
        onlineBookingTransactionId
        onlineBookingPageId
        onlineBookingCustomFields
        createdAt
        createdBy
        updatedAt
        updatedBy
        voidedAt
        voidedBy
        voidedReason
      }
      type
      title
      content
      isPublished
      timezone
      noteFolder
      attachments
      publishedAt
      notePhotos {
        documentId
        bucketName
        key
      }
      voidedAt
      voidedBy
      voidedReason
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const getInventory = /* GraphQL */ `
  query GetInventory($id: ID!) {
    getInventory(id: $id) {
      id
      locationId
      name
      code
      type
      category
      description
      sellingPrice {
        amount
        currency
        precision
        scale
      }
      costPrice {
        amount
        currency
        precision
        scale
      }
      quantity
      restockThreshold
      maxThreshold
      batchQuantity
      reorderQuantity
      location {
        id
        name
        code
        addressStreetAddress
        addressUnitNo
        addressCountry
        addressPostalCode
        taxStatus
        registeredName
        registrationNumber
        operatingHours
        telephones
        whatsapp
        emails
        isActive
        stripeAccountId
        stripeLocationId
        stripeTerminalIds
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      dosage {
        dosage
        dosageFrequency
        dosageDuration
        dosageUsage
        dosageBase
        dosageUnit
        dosageInstructions
        dosagePrecautions
        unit
      }
      isUnlimited
      quickbooksId
      promoCodes {
        id
        code
        type
        name
        description
        applicableLocationIds
        applicableServiceIds
        applicableClassServiceIds
        applicableInventoryIds
        startTime
        endTime
        isActive
        maxTotalRedemptions
        maxPerCustomerRedemptions
        currentRedemptions
        ruleTemplateType
        ruleTemplateVariables
        rules
        isStackable
        stackingPriorityLevel
        contactId
        corporateId
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const listInventories = /* GraphQL */ `
  query ListInventories($locationIds: [ID!]) {
    listInventories(locationIds: $locationIds) {
      id
      locationId
      name
      code
      type
      category
      description
      sellingPrice {
        amount
        currency
        precision
        scale
      }
      costPrice {
        amount
        currency
        precision
        scale
      }
      quantity
      restockThreshold
      maxThreshold
      batchQuantity
      reorderQuantity
      location {
        id
        name
        code
        addressStreetAddress
        addressUnitNo
        addressCountry
        addressPostalCode
        taxStatus
        registeredName
        registrationNumber
        operatingHours
        telephones
        whatsapp
        emails
        isActive
        stripeAccountId
        stripeLocationId
        stripeTerminalIds
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      dosage {
        dosage
        dosageFrequency
        dosageDuration
        dosageUsage
        dosageBase
        dosageUnit
        dosageInstructions
        dosagePrecautions
        unit
      }
      isUnlimited
      quickbooksId
      promoCodes {
        id
        code
        type
        name
        description
        applicableLocationIds
        applicableServiceIds
        applicableClassServiceIds
        applicableInventoryIds
        startTime
        endTime
        isActive
        maxTotalRedemptions
        maxPerCustomerRedemptions
        currentRedemptions
        ruleTemplateType
        ruleTemplateVariables
        rules
        isStackable
        stackingPriorityLevel
        contactId
        corporateId
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const getInvoice = /* GraphQL */ `
  query GetInvoice($id: ID!) {
    getInvoice(id: $id) {
      id
      type
      patientId
      appointmentId
      practitionerId
      locationId
      corporateId
      patient {
        id
        fullName
        isInitialized
        preferredProviderIds
        strictlyProviders
        customerShortCode
        profilePicture
        quickbooksId
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      appointments {
        id
        patientId
        serviceId
        locationId
        practitionerId
        flowActivityId
        appointmentDate
        appointmentStartTime
        appointmentEndTime
        appointmentCheckInTime
        appointmentCompleteTime
        type
        status
        emailReminderSent
        emailReminderAt
        smsReminderSent
        smsReminderSentAt
        appointmentNote
        confirmedAt
        confirmedBy
        cancellationAt
        cancellationBy
        cancellationNote
        classAppointmentId
        classServiceId
        passId
        patientPassId
        passRedemptionId
        planId
        planMilestone
        onlineBookingTransactionId
        onlineBookingPageId
        onlineBookingCustomFields
        createdAt
        createdBy
        updatedAt
        updatedBy
        voidedAt
        voidedBy
        voidedReason
      }
      items {
        id
        name
        type
        category
        description
        quantity
        reference
        discountCodes
        voidedAt
        voidedBy
        voidedReason
        createdAt
        createdBy
      }
      payments {
        id
        patientId
        practitionerId
        invoiceId
        locationId
        corporateId
        type
        mode
        status
        paymentTime
        settlementTime
        reference
        note
        paymentNumber
        isInsurance
        isRefunded
        voidEffectiveAt
        voidedAt
        voidedBy
        voidedReason
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      invoiceNumber
      totalPreTaxAmount {
        amount
        currency
        precision
        scale
      }
      taxRateUsed {
        amount
        currency
        precision
        scale
      }
      taxAmount {
        amount
        currency
        precision
        scale
      }
      postTaxAdjustments {
        id
        type
        reference
        appliedPromoCode
        voidedAt
        voidedBy
        voidedReason
        createdAt
        createdBy
      }
      totalAmountDue {
        amount
        currency
        precision
        scale
      }
      totalAmountOutstanding {
        amount
        currency
        precision
        scale
      }
      issueDate
      dueDate
      reference
      note
      voidedAt
      voidedBy
      voidedReason
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const getInvoiceByNumber = /* GraphQL */ `
  query GetInvoiceByNumber($invoiceNumber: String!) {
    getInvoiceByNumber(invoiceNumber: $invoiceNumber) {
      id
      type
      patientId
      appointmentId
      practitionerId
      locationId
      corporateId
      patient {
        id
        fullName
        isInitialized
        preferredProviderIds
        strictlyProviders
        customerShortCode
        profilePicture
        quickbooksId
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      appointments {
        id
        patientId
        serviceId
        locationId
        practitionerId
        flowActivityId
        appointmentDate
        appointmentStartTime
        appointmentEndTime
        appointmentCheckInTime
        appointmentCompleteTime
        type
        status
        emailReminderSent
        emailReminderAt
        smsReminderSent
        smsReminderSentAt
        appointmentNote
        confirmedAt
        confirmedBy
        cancellationAt
        cancellationBy
        cancellationNote
        classAppointmentId
        classServiceId
        passId
        patientPassId
        passRedemptionId
        planId
        planMilestone
        onlineBookingTransactionId
        onlineBookingPageId
        onlineBookingCustomFields
        createdAt
        createdBy
        updatedAt
        updatedBy
        voidedAt
        voidedBy
        voidedReason
      }
      items {
        id
        name
        type
        category
        description
        quantity
        reference
        discountCodes
        voidedAt
        voidedBy
        voidedReason
        createdAt
        createdBy
      }
      payments {
        id
        patientId
        practitionerId
        invoiceId
        locationId
        corporateId
        type
        mode
        status
        paymentTime
        settlementTime
        reference
        note
        paymentNumber
        isInsurance
        isRefunded
        voidEffectiveAt
        voidedAt
        voidedBy
        voidedReason
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      invoiceNumber
      totalPreTaxAmount {
        amount
        currency
        precision
        scale
      }
      taxRateUsed {
        amount
        currency
        precision
        scale
      }
      taxAmount {
        amount
        currency
        precision
        scale
      }
      postTaxAdjustments {
        id
        type
        reference
        appliedPromoCode
        voidedAt
        voidedBy
        voidedReason
        createdAt
        createdBy
      }
      totalAmountDue {
        amount
        currency
        precision
        scale
      }
      totalAmountOutstanding {
        amount
        currency
        precision
        scale
      }
      issueDate
      dueDate
      reference
      note
      voidedAt
      voidedBy
      voidedReason
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const listInvoices = /* GraphQL */ `
  query ListInvoices(
    $patientId: ID
    $appointmentId: ID
    $locationIds: [ID]
    $practitionerId: ID
    $corporateId: ID
    $issueStartTime: AWSDateTime
    $issueEndTime: AWSDateTime
    $appointmentIds: [ID!]
    $limit: Int
    $orderBy: ModelSortDirection
    $excludeVoided: Boolean
  ) {
    listInvoices(
      patientId: $patientId
      appointmentId: $appointmentId
      locationIds: $locationIds
      practitionerId: $practitionerId
      corporateId: $corporateId
      issueStartTime: $issueStartTime
      issueEndTime: $issueEndTime
      appointmentIds: $appointmentIds
      limit: $limit
      orderBy: $orderBy
      excludeVoided: $excludeVoided
    ) {
      id
      type
      patientId
      appointmentId
      practitionerId
      locationId
      corporateId
      patient {
        id
        fullName
        isInitialized
        preferredProviderIds
        strictlyProviders
        customerShortCode
        profilePicture
        quickbooksId
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      appointments {
        id
        patientId
        serviceId
        locationId
        practitionerId
        flowActivityId
        appointmentDate
        appointmentStartTime
        appointmentEndTime
        appointmentCheckInTime
        appointmentCompleteTime
        type
        status
        emailReminderSent
        emailReminderAt
        smsReminderSent
        smsReminderSentAt
        appointmentNote
        confirmedAt
        confirmedBy
        cancellationAt
        cancellationBy
        cancellationNote
        classAppointmentId
        classServiceId
        passId
        patientPassId
        passRedemptionId
        planId
        planMilestone
        onlineBookingTransactionId
        onlineBookingPageId
        onlineBookingCustomFields
        createdAt
        createdBy
        updatedAt
        updatedBy
        voidedAt
        voidedBy
        voidedReason
      }
      items {
        id
        name
        type
        category
        description
        quantity
        reference
        discountCodes
        voidedAt
        voidedBy
        voidedReason
        createdAt
        createdBy
      }
      payments {
        id
        patientId
        practitionerId
        invoiceId
        locationId
        corporateId
        type
        mode
        status
        paymentTime
        settlementTime
        reference
        note
        paymentNumber
        isInsurance
        isRefunded
        voidEffectiveAt
        voidedAt
        voidedBy
        voidedReason
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      invoiceNumber
      totalPreTaxAmount {
        amount
        currency
        precision
        scale
      }
      taxRateUsed {
        amount
        currency
        precision
        scale
      }
      taxAmount {
        amount
        currency
        precision
        scale
      }
      postTaxAdjustments {
        id
        type
        reference
        appliedPromoCode
        voidedAt
        voidedBy
        voidedReason
        createdAt
        createdBy
      }
      totalAmountDue {
        amount
        currency
        precision
        scale
      }
      totalAmountOutstanding {
        amount
        currency
        precision
        scale
      }
      issueDate
      dueDate
      reference
      note
      voidedAt
      voidedBy
      voidedReason
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const listPossibleCustomerAppointmentInvoices = /* GraphQL */ `
  query ListPossibleCustomerAppointmentInvoices(
    $customerId: ID!
    $appointmentId: ID!
  ) {
    listPossibleCustomerAppointmentInvoices(
      customerId: $customerId
      appointmentId: $appointmentId
    ) {
      id
      type
      patientId
      appointmentId
      practitionerId
      locationId
      corporateId
      patient {
        id
        fullName
        isInitialized
        preferredProviderIds
        strictlyProviders
        customerShortCode
        profilePicture
        quickbooksId
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      appointments {
        id
        patientId
        serviceId
        locationId
        practitionerId
        flowActivityId
        appointmentDate
        appointmentStartTime
        appointmentEndTime
        appointmentCheckInTime
        appointmentCompleteTime
        type
        status
        emailReminderSent
        emailReminderAt
        smsReminderSent
        smsReminderSentAt
        appointmentNote
        confirmedAt
        confirmedBy
        cancellationAt
        cancellationBy
        cancellationNote
        classAppointmentId
        classServiceId
        passId
        patientPassId
        passRedemptionId
        planId
        planMilestone
        onlineBookingTransactionId
        onlineBookingPageId
        onlineBookingCustomFields
        createdAt
        createdBy
        updatedAt
        updatedBy
        voidedAt
        voidedBy
        voidedReason
      }
      items {
        id
        name
        type
        category
        description
        quantity
        reference
        discountCodes
        voidedAt
        voidedBy
        voidedReason
        createdAt
        createdBy
      }
      payments {
        id
        patientId
        practitionerId
        invoiceId
        locationId
        corporateId
        type
        mode
        status
        paymentTime
        settlementTime
        reference
        note
        paymentNumber
        isInsurance
        isRefunded
        voidEffectiveAt
        voidedAt
        voidedBy
        voidedReason
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      invoiceNumber
      totalPreTaxAmount {
        amount
        currency
        precision
        scale
      }
      taxRateUsed {
        amount
        currency
        precision
        scale
      }
      taxAmount {
        amount
        currency
        precision
        scale
      }
      postTaxAdjustments {
        id
        type
        reference
        appliedPromoCode
        voidedAt
        voidedBy
        voidedReason
        createdAt
        createdBy
      }
      totalAmountDue {
        amount
        currency
        precision
        scale
      }
      totalAmountOutstanding {
        amount
        currency
        precision
        scale
      }
      issueDate
      dueDate
      reference
      note
      voidedAt
      voidedBy
      voidedReason
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const getPayment = /* GraphQL */ `
  query GetPayment($id: ID!) {
    getPayment(id: $id) {
      id
      patientId
      patient {
        id
        fullName
        isInitialized
        preferredProviderIds
        strictlyProviders
        customerShortCode
        profilePicture
        quickbooksId
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      practitionerId
      invoiceId
      locationId
      location {
        id
        name
        code
        addressStreetAddress
        addressUnitNo
        addressCountry
        addressPostalCode
        taxStatus
        registeredName
        registrationNumber
        operatingHours
        telephones
        whatsapp
        emails
        isActive
        stripeAccountId
        stripeLocationId
        stripeTerminalIds
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      invoice {
        id
        type
        patientId
        appointmentId
        practitionerId
        locationId
        corporateId
        invoiceNumber
        issueDate
        dueDate
        reference
        note
        voidedAt
        voidedBy
        voidedReason
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      corporateId
      type
      mode
      status
      paymentTime
      settlementTime
      amountPaid {
        amount
        currency
        precision
        scale
      }
      allocations {
        invoiceItemId
      }
      reference
      note
      paymentNumber
      isInsurance
      isRefunded
      voidEffectiveAt
      voidedAt
      voidedBy
      voidedReason
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const listPayments = /* GraphQL */ `
  query ListPayments(
    $patientId: ID
    $invoiceId: ID
    $locationIds: [ID!]
    $paymentStartTime: AWSDateTime
    $paymentEndTime: AWSDateTime
  ) {
    listPayments(
      patientId: $patientId
      invoiceId: $invoiceId
      locationIds: $locationIds
      paymentStartTime: $paymentStartTime
      paymentEndTime: $paymentEndTime
    ) {
      id
      patientId
      patient {
        id
        fullName
        isInitialized
        preferredProviderIds
        strictlyProviders
        customerShortCode
        profilePicture
        quickbooksId
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      practitionerId
      invoiceId
      locationId
      location {
        id
        name
        code
        addressStreetAddress
        addressUnitNo
        addressCountry
        addressPostalCode
        taxStatus
        registeredName
        registrationNumber
        operatingHours
        telephones
        whatsapp
        emails
        isActive
        stripeAccountId
        stripeLocationId
        stripeTerminalIds
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      invoice {
        id
        type
        patientId
        appointmentId
        practitionerId
        locationId
        corporateId
        invoiceNumber
        issueDate
        dueDate
        reference
        note
        voidedAt
        voidedBy
        voidedReason
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      corporateId
      type
      mode
      status
      paymentTime
      settlementTime
      amountPaid {
        amount
        currency
        precision
        scale
      }
      allocations {
        invoiceItemId
      }
      reference
      note
      paymentNumber
      isInsurance
      isRefunded
      voidEffectiveAt
      voidedAt
      voidedBy
      voidedReason
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const listPaymentsInDateRange = /* GraphQL */ `
  query ListPaymentsInDateRange(
    $paymentStartTime: AWSDateTime!
    $paymentEndTime: AWSDateTime!
    $locationIds: [ID!]
  ) {
    listPaymentsInDateRange(
      paymentStartTime: $paymentStartTime
      paymentEndTime: $paymentEndTime
      locationIds: $locationIds
    ) {
      id
      patientId
      patient {
        id
        fullName
        isInitialized
        preferredProviderIds
        strictlyProviders
        customerShortCode
        profilePicture
        quickbooksId
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      practitionerId
      invoiceId
      locationId
      location {
        id
        name
        code
        addressStreetAddress
        addressUnitNo
        addressCountry
        addressPostalCode
        taxStatus
        registeredName
        registrationNumber
        operatingHours
        telephones
        whatsapp
        emails
        isActive
        stripeAccountId
        stripeLocationId
        stripeTerminalIds
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      invoice {
        id
        type
        patientId
        appointmentId
        practitionerId
        locationId
        corporateId
        invoiceNumber
        issueDate
        dueDate
        reference
        note
        voidedAt
        voidedBy
        voidedReason
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      corporateId
      type
      mode
      status
      paymentTime
      settlementTime
      amountPaid {
        amount
        currency
        precision
        scale
      }
      allocations {
        invoiceItemId
      }
      reference
      note
      paymentNumber
      isInsurance
      isRefunded
      voidEffectiveAt
      voidedAt
      voidedBy
      voidedReason
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const getWalletByPatient = /* GraphQL */ `
  query GetWalletByPatient($patientId: ID!) {
    getWalletByPatient(patientId: $patientId) {
      id
      patientId
      balance {
        amount
        currency
        precision
        scale
      }
      transactions {
        id
        type
        note
        paymentId
        paymentNumber
        transactionTime
        transactionNumber
        createdAt
        createdBy
        voidedAt
        voidedBy
        voidedReason
      }
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const getSystemSettings = /* GraphQL */ `
  query GetSystemSettings {
    getSystemSettings {
      id
      invoice {
        invoicePrefix
        dueDatePeriod
        logoUrl
        invoiceTemplate
      }
      communication {
        preferMedium
      }
      medicalCertificate {
        nextSerialNumber
      }
      apiIntegrations {
        provider
        description
        baseUrl
        apiKey
        clientId
        clientSecret
      }
      timezone
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const getGlobalUISettings = /* GraphQL */ `
  query GetGlobalUISettings {
    getGlobalUISettings {
      id
      content
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const getMySettings = /* GraphQL */ `
  query GetMySettings {
    getMySettings {
      global {
        id
        content
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      user {
        id
        content
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
    }
  }
`;
export const listAppointmentCommunications = /* GraphQL */ `
  query ListAppointmentCommunications($appointmentId: ID!) {
    listAppointmentCommunications(appointmentId: $appointmentId) {
      id
      type
      medium
      status
      patientId
      patientName
      reviewerId
      invoiceId
      appointmentId
      destination
      sentTitle
      sentBody
      documents
      sentTime
      note
      trialCount
      templateId
      templateData
      extraData
      createdAt
      createdBy
      updatedAt
      updatedBy
      voidedAt
      voidedBy
      voidedReason
    }
  }
`;
export const listPatientCommunications = /* GraphQL */ `
  query ListPatientCommunications(
    $patientId: ID!
    $status: CommunicationStatus
    $startTime: AWSDateTime
    $endTime: AWSDateTime
  ) {
    listPatientCommunications(
      patientId: $patientId
      status: $status
      startTime: $startTime
      endTime: $endTime
    ) {
      id
      type
      medium
      status
      patientId
      patientName
      reviewerId
      invoiceId
      appointmentId
      destination
      sentTitle
      sentBody
      documents
      sentTime
      note
      trialCount
      templateId
      templateData
      extraData
      createdAt
      createdBy
      updatedAt
      updatedBy
      voidedAt
      voidedBy
      voidedReason
    }
  }
`;
export const listReviewerCommunications = /* GraphQL */ `
  query ListReviewerCommunications(
    $reviewerId: ID!
    $status: CommunicationStatus
    $startTime: AWSDateTime
    $endTime: AWSDateTime
  ) {
    listReviewerCommunications(
      reviewerId: $reviewerId
      status: $status
      startTime: $startTime
      endTime: $endTime
    ) {
      id
      type
      medium
      status
      patientId
      patientName
      reviewerId
      invoiceId
      appointmentId
      destination
      sentTitle
      sentBody
      documents
      sentTime
      note
      trialCount
      templateId
      templateData
      extraData
      createdAt
      createdBy
      updatedAt
      updatedBy
      voidedAt
      voidedBy
      voidedReason
    }
  }
`;
export const listCommunicationsInDateRange = /* GraphQL */ `
  query ListCommunicationsInDateRange(
    $startTime: AWSDateTime!
    $endTime: AWSDateTime!
  ) {
    listCommunicationsInDateRange(startTime: $startTime, endTime: $endTime) {
      id
      type
      medium
      status
      patientId
      patientName
      reviewerId
      invoiceId
      appointmentId
      destination
      sentTitle
      sentBody
      documents
      sentTime
      note
      trialCount
      templateId
      templateData
      extraData
      createdAt
      createdBy
      updatedAt
      updatedBy
      voidedAt
      voidedBy
      voidedReason
    }
  }
`;
export const listDocuments = /* GraphQL */ `
  query ListDocuments(
    $locationId: ID
    $practitionerId: ID
    $patientId: ID
    $appointmentId: ID
    $invoiceId: ID
    $paymentId: ID
    $customerNoteId: ID
    $type: String
  ) {
    listDocuments(
      locationId: $locationId
      practitionerId: $practitionerId
      patientId: $patientId
      appointmentId: $appointmentId
      invoiceId: $invoiceId
      paymentId: $paymentId
      customerNoteId: $customerNoteId
      type: $type
    ) {
      id
      locationId
      practitionerId
      patientId
      appointmentId
      invoiceId
      paymentId
      type
      fileName
      s3Name
      s3Bucket
      uploadStatus
      metadata
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const getCorporate = /* GraphQL */ `
  query GetCorporate($id: ID!) {
    getCorporate(id: $id) {
      id
      companyName
      code
      category
      contactPerson {
        name
        email
        phone
        isPrimary
      }
      officePhone
      officeFax
      officeEmail
      addressStreetAddress
      addressUnitNo
      addressCountry
      addressPostalCode
      websiteURL
      note
      affectsPricing
      insurancePaymentType
      insurancePaymentScheme {
        type
        isCap
      }
      invoiceNoteTemplate
      discounts {
        coverage
        type
        category
      }
      specialPricing {
        itemId
        itemType
        category
      }
      restrictions {
        itemId
        itemType
        category
      }
      validFrom
      validTo
      isActive
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const listCorporates = /* GraphQL */ `
  query ListCorporates {
    listCorporates {
      id
      companyName
      code
      category
      contactPerson {
        name
        email
        phone
        isPrimary
      }
      officePhone
      officeFax
      officeEmail
      addressStreetAddress
      addressUnitNo
      addressCountry
      addressPostalCode
      websiteURL
      note
      affectsPricing
      insurancePaymentType
      insurancePaymentScheme {
        type
        isCap
      }
      invoiceNoteTemplate
      discounts {
        coverage
        type
        category
      }
      specialPricing {
        itemId
        itemType
        category
      }
      restrictions {
        itemId
        itemType
        category
      }
      validFrom
      validTo
      isActive
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const listCorporatesByCategory = /* GraphQL */ `
  query ListCorporatesByCategory($category: String!, $onDate: AWSDateTime) {
    listCorporatesByCategory(category: $category, onDate: $onDate) {
      id
      companyName
      code
      category
      contactPerson {
        name
        email
        phone
        isPrimary
      }
      officePhone
      officeFax
      officeEmail
      addressStreetAddress
      addressUnitNo
      addressCountry
      addressPostalCode
      websiteURL
      note
      affectsPricing
      insurancePaymentType
      insurancePaymentScheme {
        type
        isCap
      }
      invoiceNoteTemplate
      discounts {
        coverage
        type
        category
      }
      specialPricing {
        itemId
        itemType
        category
      }
      restrictions {
        itemId
        itemType
        category
      }
      validFrom
      validTo
      isActive
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const getContact = /* GraphQL */ `
  query GetContact($id: ID!) {
    getContact(id: $id) {
      id
      entityName
      personName
      entityType
      category
      email
      mobile
      officePhone
      fax
      preferredModeOfContact
      addressStreetAddress
      addressUnitNo
      addressCountry
      addressPostalCode
      websiteURL
      note
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const listContacts = /* GraphQL */ `
  query ListContacts {
    listContacts {
      id
      entityName
      personName
      entityType
      category
      email
      mobile
      officePhone
      fax
      preferredModeOfContact
      addressStreetAddress
      addressUnitNo
      addressCountry
      addressPostalCode
      websiteURL
      note
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const listContactsByCategory = /* GraphQL */ `
  query ListContactsByCategory($category: String!) {
    listContactsByCategory(category: $category) {
      id
      entityName
      personName
      entityType
      category
      email
      mobile
      officePhone
      fax
      preferredModeOfContact
      addressStreetAddress
      addressUnitNo
      addressCountry
      addressPostalCode
      websiteURL
      note
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const getLoggedInUser = /* GraphQL */ `
  query GetLoggedInUser {
    getLoggedInUser {
      id
      username
      type
      preferredName
      firstName
      lastName
      middleName
      jobTitle
      profilePicture
      description
      email
      mobile
      primaryLocationId
      primaryLocation {
        id
        name
        code
        addressStreetAddress
        addressUnitNo
        addressCountry
        addressPostalCode
        taxStatus
        registeredName
        registrationNumber
        operatingHours
        telephones
        whatsapp
        emails
        isActive
        stripeAccountId
        stripeLocationId
        stripeTerminalIds
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      currentLocationId
      currentLocation {
        id
        name
        code
        addressStreetAddress
        addressUnitNo
        addressCountry
        addressPostalCode
        taxStatus
        registeredName
        registrationNumber
        operatingHours
        telephones
        whatsapp
        emails
        isActive
        stripeAccountId
        stripeLocationId
        stripeTerminalIds
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      allowedLocationIds
      allowedLocations {
        id
        name
        code
        addressStreetAddress
        addressUnitNo
        addressCountry
        addressPostalCode
        taxStatus
        registeredName
        registrationNumber
        operatingHours
        telephones
        whatsapp
        emails
        isActive
        stripeAccountId
        stripeLocationId
        stripeTerminalIds
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      roles {
        id
        name
        description
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      timezone
      country
      createdAt
      createdBy
      updatedAt
      updatedBy
      identityNumber
      identityType
      onboardDate
      resignationDate
      isActive
      viewOnlyMemberCalendars
    }
  }
`;
export const getMedicalCertificate = /* GraphQL */ `
  query GetMedicalCertificate($id: ID!) {
    getMedicalCertificate(id: $id) {
      id
      serialNumber
      content
      patientId
      appointmentId
      locationId
      practitionerId
      fromDate
      toDate
      createdAt
      createdBy
      voidedAt
      voidedBy
      voidedReason
    }
  }
`;
export const listMedicalCertificatesByPatient = /* GraphQL */ `
  query ListMedicalCertificatesByPatient(
    $patientId: ID!
    $startDate: AWSDateTime
    $endDate: AWSDateTime
  ) {
    listMedicalCertificatesByPatient(
      patientId: $patientId
      startDate: $startDate
      endDate: $endDate
    ) {
      id
      serialNumber
      content
      patientId
      appointmentId
      locationId
      practitionerId
      fromDate
      toDate
      createdAt
      createdBy
      voidedAt
      voidedBy
      voidedReason
    }
  }
`;
export const listMedicalCertificatesByLocation = /* GraphQL */ `
  query ListMedicalCertificatesByLocation(
    $locationIDs: ID!
    $startDate: AWSDateTime
    $endDate: AWSDateTime
  ) {
    listMedicalCertificatesByLocation(
      locationIDs: $locationIDs
      startDate: $startDate
      endDate: $endDate
    ) {
      id
      serialNumber
      content
      patientId
      appointmentId
      locationId
      practitionerId
      fromDate
      toDate
      createdAt
      createdBy
      voidedAt
      voidedBy
      voidedReason
    }
  }
`;
export const getTimeOff = /* GraphQL */ `
  query GetTimeOff($id: ID!) {
    getTimeOff(id: $id) {
      id
      practitionerId
      locationId
      locationCode
      title
      description
      eventStartTime
      eventEndTime
      isRecurring
      recurringFrequency
      recurringEndDate
      recurringInstances
      type
      category
      colourCode
      allDay
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const listMyTimeOffs = /* GraphQL */ `
  query ListMyTimeOffs(
    $locationIds: [ID!]
    $startTime: AWSDateTime
    $endTime: AWSDateTime
  ) {
    listMyTimeOffs(
      locationIds: $locationIds
      startTime: $startTime
      endTime: $endTime
    ) {
      id
      practitionerId
      locationId
      locationCode
      title
      description
      eventStartTime
      eventEndTime
      isRecurring
      recurringFrequency
      recurringEndDate
      recurringInstances
      type
      category
      colourCode
      allDay
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const listTimeOffs = /* GraphQL */ `
  query ListTimeOffs(
    $practitionerIds: [ID!]
    $locationIds: [ID!]
    $startTime: AWSDateTime
    $endTime: AWSDateTime
  ) {
    listTimeOffs(
      practitionerIds: $practitionerIds
      locationIds: $locationIds
      startTime: $startTime
      endTime: $endTime
    ) {
      id
      practitionerId
      locationId
      locationCode
      title
      description
      eventStartTime
      eventEndTime
      isRecurring
      recurringFrequency
      recurringEndDate
      recurringInstances
      type
      category
      colourCode
      allDay
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const getPatientSignature = /* GraphQL */ `
  query GetPatientSignature($id: ID, $serialNumber: String) {
    getPatientSignature(id: $id, serialNumber: $serialNumber) {
      id
      serialNumber
      documentContent
      documentNameSigned
      patientSignatureContent
      practitionerSignatureContent
      patientId
      appointmentId
      locationId
      practitionerId
      additionalSignatories {
        role
        name
        signatureContent
      }
      createdAt
      createdBy
      voidedAt
      voidedBy
      voidedReason
    }
  }
`;
export const listPatientSignatures = /* GraphQL */ `
  query ListPatientSignatures(
    $patientId: ID
    $practitionerId: ID
    $startTime: AWSDateTime
    $endTime: AWSDateTime
  ) {
    listPatientSignatures(
      patientId: $patientId
      practitionerId: $practitionerId
      startTime: $startTime
      endTime: $endTime
    ) {
      id
      serialNumber
      documentContent
      documentNameSigned
      patientSignatureContent
      practitionerSignatureContent
      patientId
      appointmentId
      locationId
      practitionerId
      additionalSignatories {
        role
        name
        signatureContent
      }
      createdAt
      createdBy
      voidedAt
      voidedBy
      voidedReason
    }
  }
`;
export const getOnlineBookingPage = /* GraphQL */ `
  query GetOnlineBookingPage($id: ID!) {
    getOnlineBookingPage(id: $id) {
      id
      tenantId
      tenantCode
      name
      urlSlug
      serviceIds
      services {
        id
        locationId
        practitionerId
        name
        code
        description
        onlineBookingDescription
        category
        duration
        quickbooksId
        workingHours
        onlineBookingName
        onlineBookingCategory
        isActive
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      classServiceIds
      classServices {
        id
        locationId
        practitionerId
        name
        code
        description
        onlineBookingDescription
        category
        duration
        maxParticipants
        onlineBookingName
        onlineBookingCategory
        isActive
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      practitionerIds
      practitioners {
        id
        username
        type
        preferredName
        firstName
        lastName
        middleName
        jobTitle
        profilePicture
        description
        email
        mobile
        primaryLocationId
        currentLocationId
        allowedLocationIds
        timezone
        country
        createdAt
        createdBy
        updatedAt
        updatedBy
        identityNumber
        identityType
        onboardDate
        resignationDate
        isActive
        viewOnlyMemberCalendars
        registrationAuthority
        registrationNumber
        discipline
        qualifications
      }
      locationIds
      locations {
        id
        name
        code
        addressStreetAddress
        addressUnitNo
        addressCountry
        addressPostalCode
        taxStatus
        registeredName
        registrationNumber
        operatingHours
        telephones
        whatsapp
        emails
        isActive
        stripeAccountId
        stripeLocationId
        stripeTerminalIds
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      isActive
      validFrom
      validTo
      headHTML
      contentHTML
      bookingFinePrint
      paymentRequired
      paymentType
      promoCodeIds
      promoCodes {
        id
        code
        type
        name
        description
        applicableLocationIds
        applicableServiceIds
        applicableClassServiceIds
        applicableInventoryIds
        startTime
        endTime
        isActive
        maxTotalRedemptions
        maxPerCustomerRedemptions
        currentRedemptions
        ruleTemplateType
        ruleTemplateVariables
        rules
        isStackable
        stackingPriorityLevel
        contactId
        corporateId
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      paymentAmount {
        amount
        currency
        precision
        scale
      }
      paymentAmountType
      customFields
      notificationEmailFromName
      notificationEmailAddress
      emailVerificationEmailTemplate
      bookingConfirmationEmailTemplate
      bookingEmailBccList
      allowedDaysInFuture
      appointmentHoldTime
      thirdPartyTools
      createInvoiceUpfront
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const getOnlineBookingBySlug = /* GraphQL */ `
  query GetOnlineBookingBySlug($urlSlug: String!, $tenantId: String!) {
    getOnlineBookingBySlug(urlSlug: $urlSlug, tenantId: $tenantId) {
      id
      tenantId
      tenantCode
      name
      urlSlug
      serviceIds
      services {
        id
        locationId
        practitionerId
        name
        code
        description
        onlineBookingDescription
        category
        duration
        quickbooksId
        workingHours
        onlineBookingName
        onlineBookingCategory
        isActive
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      classServiceIds
      classServices {
        id
        locationId
        practitionerId
        name
        code
        description
        onlineBookingDescription
        category
        duration
        maxParticipants
        onlineBookingName
        onlineBookingCategory
        isActive
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      practitionerIds
      practitioners {
        id
        username
        type
        preferredName
        firstName
        lastName
        middleName
        jobTitle
        profilePicture
        description
        email
        mobile
        primaryLocationId
        currentLocationId
        allowedLocationIds
        timezone
        country
        createdAt
        createdBy
        updatedAt
        updatedBy
        identityNumber
        identityType
        onboardDate
        resignationDate
        isActive
        viewOnlyMemberCalendars
        registrationAuthority
        registrationNumber
        discipline
        qualifications
      }
      locationIds
      locations {
        id
        name
        code
        addressStreetAddress
        addressUnitNo
        addressCountry
        addressPostalCode
        taxStatus
        registeredName
        registrationNumber
        operatingHours
        telephones
        whatsapp
        emails
        isActive
        stripeAccountId
        stripeLocationId
        stripeTerminalIds
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      isActive
      validFrom
      validTo
      headHTML
      contentHTML
      bookingFinePrint
      paymentRequired
      paymentType
      promoCodeIds
      promoCodes {
        id
        code
        type
        name
        description
        applicableLocationIds
        applicableServiceIds
        applicableClassServiceIds
        applicableInventoryIds
        startTime
        endTime
        isActive
        maxTotalRedemptions
        maxPerCustomerRedemptions
        currentRedemptions
        ruleTemplateType
        ruleTemplateVariables
        rules
        isStackable
        stackingPriorityLevel
        contactId
        corporateId
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      paymentAmount {
        amount
        currency
        precision
        scale
      }
      paymentAmountType
      customFields
      notificationEmailFromName
      notificationEmailAddress
      emailVerificationEmailTemplate
      bookingConfirmationEmailTemplate
      bookingEmailBccList
      allowedDaysInFuture
      appointmentHoldTime
      thirdPartyTools
      createInvoiceUpfront
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const listOnlineBookingPages = /* GraphQL */ `
  query ListOnlineBookingPages {
    listOnlineBookingPages {
      id
      tenantId
      tenantCode
      name
      urlSlug
      serviceIds
      services {
        id
        locationId
        practitionerId
        name
        code
        description
        onlineBookingDescription
        category
        duration
        quickbooksId
        workingHours
        onlineBookingName
        onlineBookingCategory
        isActive
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      classServiceIds
      classServices {
        id
        locationId
        practitionerId
        name
        code
        description
        onlineBookingDescription
        category
        duration
        maxParticipants
        onlineBookingName
        onlineBookingCategory
        isActive
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      practitionerIds
      practitioners {
        id
        username
        type
        preferredName
        firstName
        lastName
        middleName
        jobTitle
        profilePicture
        description
        email
        mobile
        primaryLocationId
        currentLocationId
        allowedLocationIds
        timezone
        country
        createdAt
        createdBy
        updatedAt
        updatedBy
        identityNumber
        identityType
        onboardDate
        resignationDate
        isActive
        viewOnlyMemberCalendars
        registrationAuthority
        registrationNumber
        discipline
        qualifications
      }
      locationIds
      locations {
        id
        name
        code
        addressStreetAddress
        addressUnitNo
        addressCountry
        addressPostalCode
        taxStatus
        registeredName
        registrationNumber
        operatingHours
        telephones
        whatsapp
        emails
        isActive
        stripeAccountId
        stripeLocationId
        stripeTerminalIds
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      isActive
      validFrom
      validTo
      headHTML
      contentHTML
      bookingFinePrint
      paymentRequired
      paymentType
      promoCodeIds
      promoCodes {
        id
        code
        type
        name
        description
        applicableLocationIds
        applicableServiceIds
        applicableClassServiceIds
        applicableInventoryIds
        startTime
        endTime
        isActive
        maxTotalRedemptions
        maxPerCustomerRedemptions
        currentRedemptions
        ruleTemplateType
        ruleTemplateVariables
        rules
        isStackable
        stackingPriorityLevel
        contactId
        corporateId
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      paymentAmount {
        amount
        currency
        precision
        scale
      }
      paymentAmountType
      customFields
      notificationEmailFromName
      notificationEmailAddress
      emailVerificationEmailTemplate
      bookingConfirmationEmailTemplate
      bookingEmailBccList
      allowedDaysInFuture
      appointmentHoldTime
      thirdPartyTools
      createInvoiceUpfront
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const getOnlineBookingTransaction = /* GraphQL */ `
  query GetOnlineBookingTransaction($id: ID!) {
    getOnlineBookingTransaction(id: $id) {
      id
      onlineBookingPageId
      patientName
      patientEmail
      patientMobile
      serviceId
      classServiceId
      locationId
      startTime
      endTime
      patientId
      classAppointmentId
      appointmentId
      originalPrice {
        amount
        currency
        precision
        scale
      }
      adjustmentCodes {
        id
        code
        type
        name
        description
        applicableLocationIds
        applicableServiceIds
        applicableClassServiceIds
        applicableInventoryIds
        startTime
        endTime
        isActive
        maxTotalRedemptions
        maxPerCustomerRedemptions
        currentRedemptions
        ruleTemplateType
        ruleTemplateVariables
        rules
        isStackable
        stackingPriorityLevel
        contactId
        corporateId
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      promoCodeAdjustments {
        id
        type
        reference
        appliedPromoCode
        voidedAt
        voidedBy
        voidedReason
        createdAt
        createdBy
      }
      paymentDue {
        amount
        currency
        precision
        scale
      }
      status
      paymentRequired
      paymentStatus
      stripeChargeIds
      customFields
      urlQueryParams
      communicationId
      paymentIds
      paidAt
      confirmedAt
      confirmedBy
      createdAt
      createdBy
      updatedAt
      updatedBy
      voidedAt
      voidedBy
      voidReason
      expiredAt
      stripeIntegrated
      stripeSetupIntentUrl
      multiplePatients
    }
  }
`;
export const listOnlineBookingTransactions = /* GraphQL */ `
  query ListOnlineBookingTransactions(
    $locationIds: [ID!]
    $patientId: ID
    $startTime: AWSDateTime
    $endTime: AWSDateTime
  ) {
    listOnlineBookingTransactions(
      locationIds: $locationIds
      patientId: $patientId
      startTime: $startTime
      endTime: $endTime
    ) {
      id
      onlineBookingPageId
      patientName
      patientEmail
      patientMobile
      serviceId
      classServiceId
      locationId
      startTime
      endTime
      patientId
      classAppointmentId
      appointmentId
      originalPrice {
        amount
        currency
        precision
        scale
      }
      adjustmentCodes {
        id
        code
        type
        name
        description
        applicableLocationIds
        applicableServiceIds
        applicableClassServiceIds
        applicableInventoryIds
        startTime
        endTime
        isActive
        maxTotalRedemptions
        maxPerCustomerRedemptions
        currentRedemptions
        ruleTemplateType
        ruleTemplateVariables
        rules
        isStackable
        stackingPriorityLevel
        contactId
        corporateId
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      promoCodeAdjustments {
        id
        type
        reference
        appliedPromoCode
        voidedAt
        voidedBy
        voidedReason
        createdAt
        createdBy
      }
      paymentDue {
        amount
        currency
        precision
        scale
      }
      status
      paymentRequired
      paymentStatus
      stripeChargeIds
      customFields
      urlQueryParams
      communicationId
      paymentIds
      paidAt
      confirmedAt
      confirmedBy
      createdAt
      createdBy
      updatedAt
      updatedBy
      voidedAt
      voidedBy
      voidReason
      expiredAt
      stripeIntegrated
      stripeSetupIntentUrl
      multiplePatients
    }
  }
`;
export const getNoticeBoardMessage = /* GraphQL */ `
  query GetNoticeBoardMessage($id: ID!) {
    getNoticeBoardMessage(id: $id) {
      id
      locationId
      content
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const listNoticeBoardMessages = /* GraphQL */ `
  query ListNoticeBoardMessages(
    $locationId: ID!
    $startTime: AWSDateTime
    $endTime: AWSDateTime
  ) {
    listNoticeBoardMessages(
      locationId: $locationId
      startTime: $startTime
      endTime: $endTime
    ) {
      id
      locationId
      content
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const googleCalendarIntegrationLink = /* GraphQL */ `
  query GoogleCalendarIntegrationLink {
    googleCalendarIntegrationLink
  }
`;
export const getPatientInvoiceReport = /* GraphQL */ `
  query GetPatientInvoiceReport(
    $locationIds: [ID!]!
    $startTime: AWSDateTime!
    $endTime: AWSDateTime!
  ) {
    getPatientInvoiceReport(
      locationIds: $locationIds
      startTime: $startTime
      endTime: $endTime
    ) {
      locationId
      amountDue {
        amount
        currency
        precision
        scale
      }
      amountOutstanding {
        amount
        currency
        precision
        scale
      }
    }
  }
`;
export const getCorporateInvoiceReport = /* GraphQL */ `
  query GetCorporateInvoiceReport(
    $locationIds: [ID!]!
    $startTime: AWSDateTime!
    $endTime: AWSDateTime!
  ) {
    getCorporateInvoiceReport(
      locationIds: $locationIds
      startTime: $startTime
      endTime: $endTime
    ) {
      locationId
      amountDue {
        amount
        currency
        precision
        scale
      }
      amountOutstanding {
        amount
        currency
        precision
        scale
      }
    }
  }
`;
export const getLocationsAppointmentReferralReport = /* GraphQL */ `
  query GetLocationsAppointmentReferralReport(
    $locationIds: [ID!]!
    $startTime: AWSDateTime!
    $endTime: AWSDateTime!
  ) {
    getLocationsAppointmentReferralReport(
      locationIds: $locationIds
      startTime: $startTime
      endTime: $endTime
    ) {
      locationId
      allPatients {
        firstVisit
        followUpVisit
      }
      referrals {
        referredBy
        firstVisit
        followUpVisit
      }
    }
  }
`;
export const getMyPaymentReports = /* GraphQL */ `
  query GetMyPaymentReports($startTime: AWSDateTime!, $endTime: AWSDateTime!) {
    getMyPaymentReports(startTime: $startTime, endTime: $endTime) {
      summary {
        practitionerId
        practitionerName
      }
      details {
        practitionerId
        paymentDate
        invoiceItem
        invoiceNumber
        patientName
        locationId
        paymentCreatedAt
        itemType
      }
    }
  }
`;
export const getPractitionerPaymentReports = /* GraphQL */ `
  query GetPractitionerPaymentReports(
    $practitionerIds: [ID!]!
    $startTime: AWSDateTime!
    $endTime: AWSDateTime!
  ) {
    getPractitionerPaymentReports(
      practitionerIds: $practitionerIds
      startTime: $startTime
      endTime: $endTime
    ) {
      summary {
        practitionerId
        practitionerName
      }
      details {
        practitionerId
        paymentDate
        invoiceItem
        invoiceNumber
        patientName
        locationId
        paymentCreatedAt
        itemType
      }
    }
  }
`;
export const listCommunicationTemplates = /* GraphQL */ `
  query ListCommunicationTemplates {
    listCommunicationTemplates {
      id
      name
      type
      description
      template
      from {
        email
        name
      }
      replyTo {
        email
        name
      }
      bcc
      cc
      emailSubject
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const verifyUninitializedPatient = /* GraphQL */ `
  query VerifyUninitializedPatient(
    $tenantId: String!
    $id: ID!
    $email: String!
    $phoneNumber: String!
  ) {
    verifyUninitializedPatient(
      tenantId: $tenantId
      id: $id
      email: $email
      phoneNumber: $phoneNumber
    ) {
      uninitialized
      formOptions
    }
  }
`;
export const getClassAppointment = /* GraphQL */ `
  query GetClassAppointment($id: ID!) {
    getClassAppointment(id: $id) {
      id
      classServiceId
      classService {
        id
        locationId
        practitionerId
        name
        code
        description
        onlineBookingDescription
        category
        duration
        maxParticipants
        onlineBookingName
        onlineBookingCategory
        isActive
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      practitionerId
      practitioner {
        id
        username
        type
        preferredName
        firstName
        lastName
        middleName
        jobTitle
        profilePicture
        description
        email
        mobile
        primaryLocationId
        currentLocationId
        allowedLocationIds
        timezone
        country
        createdAt
        createdBy
        updatedAt
        updatedBy
        identityNumber
        identityType
        onboardDate
        resignationDate
        isActive
        viewOnlyMemberCalendars
        registrationAuthority
        registrationNumber
        discipline
        qualifications
      }
      locationId
      location {
        id
        name
        code
        addressStreetAddress
        addressUnitNo
        addressCountry
        addressPostalCode
        taxStatus
        registeredName
        registrationNumber
        operatingHours
        telephones
        whatsapp
        emails
        isActive
        stripeAccountId
        stripeLocationId
        stripeTerminalIds
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      classAppointmentDate
      classAppointmentStartTime
      classAppointmentEndTime
      classPublicNotes
      status
      recurId
      maxParticipants
      patientIds
      classNotes
      isBookingAllowed
      recurFrequency
      recurStartDate
      recurEndDate
      recurInstances
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const getClassAppointmentSeries = /* GraphQL */ `
  query GetClassAppointmentSeries($id: ID!) {
    getClassAppointmentSeries(id: $id) {
      id
      classServiceId
      classService {
        id
        locationId
        practitionerId
        name
        code
        description
        onlineBookingDescription
        category
        duration
        maxParticipants
        onlineBookingName
        onlineBookingCategory
        isActive
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      practitionerId
      practitioner {
        id
        username
        type
        preferredName
        firstName
        lastName
        middleName
        jobTitle
        profilePicture
        description
        email
        mobile
        primaryLocationId
        currentLocationId
        allowedLocationIds
        timezone
        country
        createdAt
        createdBy
        updatedAt
        updatedBy
        identityNumber
        identityType
        onboardDate
        resignationDate
        isActive
        viewOnlyMemberCalendars
        registrationAuthority
        registrationNumber
        discipline
        qualifications
      }
      locationId
      location {
        id
        name
        code
        addressStreetAddress
        addressUnitNo
        addressCountry
        addressPostalCode
        taxStatus
        registeredName
        registrationNumber
        operatingHours
        telephones
        whatsapp
        emails
        isActive
        stripeAccountId
        stripeLocationId
        stripeTerminalIds
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      classAppointmentDate
      classAppointmentStartTime
      classAppointmentEndTime
      classPublicNotes
      status
      recurId
      maxParticipants
      patientIds
      classNotes
      isBookingAllowed
      recurFrequency
      recurStartDate
      recurEndDate
      recurInstances
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const listClassAppointments = /* GraphQL */ `
  query ListClassAppointments(
    $classAppointmentStartDate: AWSDate
    $classAppointmentEndDate: AWSDate
    $practitionerIds: [ID!]
    $locationIds: [ID!]
    $classServiceIds: [ID!]
    $statuses: [String!]
  ) {
    listClassAppointments(
      classAppointmentStartDate: $classAppointmentStartDate
      classAppointmentEndDate: $classAppointmentEndDate
      practitionerIds: $practitionerIds
      locationIds: $locationIds
      classServiceIds: $classServiceIds
      statuses: $statuses
    ) {
      id
      classServiceId
      classService {
        id
        locationId
        practitionerId
        name
        code
        description
        onlineBookingDescription
        category
        duration
        maxParticipants
        onlineBookingName
        onlineBookingCategory
        isActive
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      practitionerId
      practitioner {
        id
        username
        type
        preferredName
        firstName
        lastName
        middleName
        jobTitle
        profilePicture
        description
        email
        mobile
        primaryLocationId
        currentLocationId
        allowedLocationIds
        timezone
        country
        createdAt
        createdBy
        updatedAt
        updatedBy
        identityNumber
        identityType
        onboardDate
        resignationDate
        isActive
        viewOnlyMemberCalendars
        registrationAuthority
        registrationNumber
        discipline
        qualifications
      }
      locationId
      location {
        id
        name
        code
        addressStreetAddress
        addressUnitNo
        addressCountry
        addressPostalCode
        taxStatus
        registeredName
        registrationNumber
        operatingHours
        telephones
        whatsapp
        emails
        isActive
        stripeAccountId
        stripeLocationId
        stripeTerminalIds
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      classAppointmentDate
      classAppointmentStartTime
      classAppointmentEndTime
      classPublicNotes
      status
      recurId
      maxParticipants
      patientIds
      classNotes
      isBookingAllowed
      recurFrequency
      recurStartDate
      recurEndDate
      recurInstances
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const getPass = /* GraphQL */ `
  query GetPass($id: ID!) {
    getPass(id: $id) {
      id
      name
      code
      description
      eligibleClassServices
      eligibleServices
      type
      maxRedemptionsPerDay
      maxRedemptionsPerWeek
      maxRedemptionsPerMonth
      validityPeriod
      totalSessions
      price {
        amount
        currency
        precision
        scale
      }
      redemptionValue {
        amount
        currency
        precision
        scale
      }
      decrementCancellations
      isActive
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const listPasses = /* GraphQL */ `
  query ListPasses(
    $serviceIds: [ID!]
    $classServiceIds: [ID!]
    $isActive: Boolean
  ) {
    listPasses(
      serviceIds: $serviceIds
      classServiceIds: $classServiceIds
      isActive: $isActive
    ) {
      id
      name
      code
      description
      eligibleClassServices
      eligibleServices
      type
      maxRedemptionsPerDay
      maxRedemptionsPerWeek
      maxRedemptionsPerMonth
      validityPeriod
      totalSessions
      price {
        amount
        currency
        precision
        scale
      }
      redemptionValue {
        amount
        currency
        precision
        scale
      }
      decrementCancellations
      isActive
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const getPassPurchase = /* GraphQL */ `
  query GetPassPurchase($id: ID!) {
    getPassPurchase(id: $id) {
      id
      patientId
      corporateId
      practitionerId
      salePersonId
      passId
      invoiceId
      purchaseDate
      totalPassesPurchased
      totalPassesAssigned
      createdAt
      createdBy
      voidedAt
      voidedBy
      voidedReason
    }
  }
`;
export const listPassPurchases = /* GraphQL */ `
  query ListPassPurchases(
    $invoiceId: ID
    $patientId: ID
    $corporateId: ID
    $passId: ID
    $practitionerId: ID
    $salePersonId: ID
    $purchasedAfter: AWSDate
    $purchasedBefore: AWSDate
  ) {
    listPassPurchases(
      invoiceId: $invoiceId
      patientId: $patientId
      corporateId: $corporateId
      passId: $passId
      practitionerId: $practitionerId
      salePersonId: $salePersonId
      purchasedAfter: $purchasedAfter
      purchasedBefore: $purchasedBefore
    ) {
      id
      patientId
      corporateId
      practitionerId
      salePersonId
      passId
      invoiceId
      purchaseDate
      totalPassesPurchased
      totalPassesAssigned
      createdAt
      createdBy
      voidedAt
      voidedBy
      voidedReason
    }
  }
`;
export const getPatientPass = /* GraphQL */ `
  query GetPatientPass($id: ID!) {
    getPatientPass(id: $id) {
      id
      ownerId
      ownerCorporateId
      patientIds
      passId
      passName
      passCode
      purchaseId
      invoiceId
      purchasedLocationId
      eligibleClassServices
      eligibleServices
      status
      note
      totalRedemptions
      redemptionsMade
      redemptionsRemaining
      redemptionValue {
        amount
        currency
        precision
        scale
      }
      expiryDate
      assignedDate
      purchaseDate
      purchasedLocation {
        id
        name
        code
        addressStreetAddress
        addressUnitNo
        addressCountry
        addressPostalCode
        taxStatus
        registeredName
        registrationNumber
        operatingHours
        telephones
        whatsapp
        emails
        isActive
        stripeAccountId
        stripeLocationId
        stripeTerminalIds
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      passRedemptions {
        id
        patientPassId
        patientId
        locationId
        appointmentId
        practitionerId
        serviceId
        classId
        classServiceId
        invoiceId
        redemptionNote
        createdAt
        createdBy
        voidedAt
        voidedBy
        voidedReason
      }
      voidedAt
      voidedBy
      voidedReason
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const listPatientPasses = /* GraphQL */ `
  query ListPatientPasses($patientId: ID!) {
    listPatientPasses(patientId: $patientId) {
      id
      ownerId
      ownerCorporateId
      patientIds
      passId
      passName
      passCode
      purchaseId
      invoiceId
      purchasedLocationId
      eligibleClassServices
      eligibleServices
      status
      note
      totalRedemptions
      redemptionsMade
      redemptionsRemaining
      redemptionValue {
        amount
        currency
        precision
        scale
      }
      expiryDate
      assignedDate
      purchaseDate
      purchasedLocation {
        id
        name
        code
        addressStreetAddress
        addressUnitNo
        addressCountry
        addressPostalCode
        taxStatus
        registeredName
        registrationNumber
        operatingHours
        telephones
        whatsapp
        emails
        isActive
        stripeAccountId
        stripeLocationId
        stripeTerminalIds
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      passRedemptions {
        id
        patientPassId
        patientId
        locationId
        appointmentId
        practitionerId
        serviceId
        classId
        classServiceId
        invoiceId
        redemptionNote
        createdAt
        createdBy
        voidedAt
        voidedBy
        voidedReason
      }
      voidedAt
      voidedBy
      voidedReason
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const getCustomerFeedback = /* GraphQL */ `
  query GetCustomerFeedback($id: ID!) {
    getCustomerFeedback(id: $id) {
      id
      locationId
      customerId
      content {
        id
        type
        name
        text
        options
        value
      }
      createdAt
      createdBy
      updatedAt
      updatedBy
      voidedAt
      voidedBy
      voidedReason
    }
  }
`;
export const listCustomerFeedbacks = /* GraphQL */ `
  query ListCustomerFeedbacks(
    $customerId: ID
    $locationId: ID
    $startTime: AWSDateTime!
    $endTime: AWSDateTime!
  ) {
    listCustomerFeedbacks(
      customerId: $customerId
      locationId: $locationId
      startTime: $startTime
      endTime: $endTime
    ) {
      id
      locationId
      customerId
      content {
        id
        type
        name
        text
        options
        value
      }
      createdAt
      createdBy
      updatedAt
      updatedBy
      voidedAt
      voidedBy
      voidedReason
    }
  }
`;
export const getCustomerMemo = /* GraphQL */ `
  query GetCustomerMemo($id: ID!) {
    getCustomerMemo(id: $id) {
      id
      locationId
      customerId
      parentMemoId
      content
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const listCustomerMemos = /* GraphQL */ `
  query ListCustomerMemos(
    $customerId: ID
    $locationId: ID
    $startTime: AWSDateTime!
    $endTime: AWSDateTime!
  ) {
    listCustomerMemos(
      customerId: $customerId
      locationId: $locationId
      startTime: $startTime
      endTime: $endTime
    ) {
      id
      locationId
      customerId
      parentMemoId
      content
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const getBlock = /* GraphQL */ `
  query GetBlock($id: ID!) {
    getBlock(id: $id) {
      id
      locationId
      customerId
      appointmentId
      flowActivityId
      classAppointmentId
      name
      category
      subCategory
      status
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const listBlocks = /* GraphQL */ `
  query ListBlocks(
    $customerId: ID
    $appointmentId: ID
    $classAppointmentId: ID
  ) {
    listBlocks(
      customerId: $customerId
      appointmentId: $appointmentId
      classAppointmentId: $classAppointmentId
    ) {
      id
      locationId
      customerId
      appointmentId
      flowActivityId
      classAppointmentId
      name
      category
      subCategory
      status
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const getStation = /* GraphQL */ `
  query GetStation($id: ID!) {
    getStation(id: $id) {
      id
      locationId
      providerId
      name
      capacity
      customerActivities {
        activityId
        activityType
        customerId
        customerName
        customerShortCode
        customerPreferredProviderIds
        strictlyProviders
        stationId
        stationName
        updatedAt
        createdAt
      }
      isInSession
      isActive
      activitySessionId
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const listStations = /* GraphQL */ `
  query ListStations(
    $activitySessionId: ID
    $locationId: ID
    $practitionerId: ID
  ) {
    listStations(
      activitySessionId: $activitySessionId
      locationId: $locationId
      practitionerId: $practitionerId
    ) {
      id
      locationId
      providerId
      name
      capacity
      customerActivities {
        activityId
        activityType
        customerId
        customerName
        customerShortCode
        customerPreferredProviderIds
        strictlyProviders
        stationId
        stationName
        updatedAt
        createdAt
      }
      isInSession
      isActive
      activitySessionId
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const getResourceSchedule = /* GraphQL */ `
  query GetResourceSchedule(
    $resourceId: ID!
    $resourceType: String!
    $year: Int!
  ) {
    getResourceSchedule(
      resourceId: $resourceId
      resourceType: $resourceType
      year: $year
    ) {
      id
      resourceId
      resourceType
      year
      days {
        day
      }
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const getResourceScheduleSlots = /* GraphQL */ `
  query GetResourceScheduleSlots(
    $resourceId: ID!
    $resourceType: String!
    $startTime: AWSDateTime!
    $endTime: AWSDateTime!
  ) {
    getResourceScheduleSlots(
      resourceId: $resourceId
      resourceType: $resourceType
      startTime: $startTime
      endTime: $endTime
    ) {
      startTime
      endTime
    }
  }
`;
export const getResourceSchedulesInRange = /* GraphQL */ `
  query GetResourceSchedulesInRange(
    $resourceId: ID!
    $resourceType: String!
    $startTime: AWSDateTime!
    $endTime: AWSDateTime!
  ) {
    getResourceSchedulesInRange(
      resourceId: $resourceId
      resourceType: $resourceType
      startTime: $startTime
      endTime: $endTime
    ) {
      id
      resourceId
      resourceType
      year
      days {
        day
      }
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const getPromoCode = /* GraphQL */ `
  query GetPromoCode($id: ID!) {
    getPromoCode(id: $id) {
      id
      code
      type
      name
      description
      applicableLocationIds
      applicableServiceIds
      applicableClassServiceIds
      applicableInventoryIds
      startTime
      endTime
      isActive
      promoCodeValue {
        type
      }
      maxTotalRedemptions
      maxPerCustomerRedemptions
      currentRedemptions
      ruleTemplateType
      ruleTemplateVariables
      rules
      isStackable
      stackingPriorityLevel
      contactId
      corporateId
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const listPromoCodes = /* GraphQL */ `
  query ListPromoCodes(
    $locationId: ID
    $serviceId: ID
    $classServiceId: ID
    $inventoryId: ID
    $contactId: ID
    $corporateId: ID
    $validStartTime: AWSDateTime
    $validEndTime: AWSDateTime
    $createdStartTime: AWSDateTime
    $createdEndTime: AWSDateTime
  ) {
    listPromoCodes(
      locationId: $locationId
      serviceId: $serviceId
      classServiceId: $classServiceId
      inventoryId: $inventoryId
      contactId: $contactId
      corporateId: $corporateId
      validStartTime: $validStartTime
      validEndTime: $validEndTime
      createdStartTime: $createdStartTime
      createdEndTime: $createdEndTime
    ) {
      id
      code
      type
      name
      description
      applicableLocationIds
      applicableServiceIds
      applicableClassServiceIds
      applicableInventoryIds
      startTime
      endTime
      isActive
      promoCodeValue {
        type
      }
      maxTotalRedemptions
      maxPerCustomerRedemptions
      currentRedemptions
      ruleTemplateType
      ruleTemplateVariables
      rules
      isStackable
      stackingPriorityLevel
      contactId
      corporateId
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const listPromoCodeRedemptions = /* GraphQL */ `
  query ListPromoCodeRedemptions(
    $promoCodeId: ID
    $customerId: ID
    $redemptionStartTime: AWSDateTime!
    $redemptionEndTime: AWSDateTime!
  ) {
    listPromoCodeRedemptions(
      promoCodeId: $promoCodeId
      customerId: $customerId
      redemptionStartTime: $redemptionStartTime
      redemptionEndTime: $redemptionEndTime
    ) {
      id
      promoCodeId
      promoCodeCode
      promoCodeType
      locationId
      customerId
      customerName
      redemptionCount
      onlineBookingPageId
      onlineBookingTransactionId
      appointmentId
      invoiceId
      invoiceNumber
      providerId
      providerName
      serviceId
      serviceName
      inventoryId
      inventoryName
      classServiceId
      classServiceName
      createdAt
      createdBy
      updatedAt
      updatedBy
      voidedAt
      voidedBy
      voidedReason
    }
  }
`;
export const checkServicePromoCode = /* GraphQL */ `
  query CheckServicePromoCode(
    $tenantId: ID!
    $onlineBookingPageId: ID!
    $serviceId: ID!
    $code: String!
  ) {
    checkServicePromoCode(
      tenantId: $tenantId
      onlineBookingPageId: $onlineBookingPageId
      serviceId: $serviceId
      code: $code
    ) {
      id
      code
      type
      name
      description
      applicableLocationIds
      applicableServiceIds
      applicableClassServiceIds
      applicableInventoryIds
      startTime
      endTime
      isActive
      promoCodeValue {
        type
      }
      maxTotalRedemptions
      maxPerCustomerRedemptions
      currentRedemptions
      ruleTemplateType
      ruleTemplateVariables
      rules
      isStackable
      stackingPriorityLevel
      contactId
      corporateId
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const listTodayCustomerClassAppointments = /* GraphQL */ `
  query ListTodayCustomerClassAppointments($customerShortCode: String!) {
    listTodayCustomerClassAppointments(customerShortCode: $customerShortCode) {
      id
      classServiceId
      classService {
        id
        locationId
        practitionerId
        name
        code
        description
        onlineBookingDescription
        category
        duration
        maxParticipants
        onlineBookingName
        onlineBookingCategory
        isActive
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      practitionerId
      practitioner {
        id
        username
        type
        preferredName
        firstName
        lastName
        middleName
        jobTitle
        profilePicture
        description
        email
        mobile
        primaryLocationId
        currentLocationId
        allowedLocationIds
        timezone
        country
        createdAt
        createdBy
        updatedAt
        updatedBy
        identityNumber
        identityType
        onboardDate
        resignationDate
        isActive
        viewOnlyMemberCalendars
        registrationAuthority
        registrationNumber
        discipline
        qualifications
      }
      locationId
      location {
        id
        name
        code
        addressStreetAddress
        addressUnitNo
        addressCountry
        addressPostalCode
        taxStatus
        registeredName
        registrationNumber
        operatingHours
        telephones
        whatsapp
        emails
        isActive
        stripeAccountId
        stripeLocationId
        stripeTerminalIds
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      classAppointmentDate
      classAppointmentStartTime
      classAppointmentEndTime
      classPublicNotes
      status
      recurId
      maxParticipants
      patientIds
      classNotes
      isBookingAllowed
      recurFrequency
      recurStartDate
      recurEndDate
      recurInstances
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const getFlowActivitySession = /* GraphQL */ `
  query GetFlowActivitySession($id: ID!) {
    getFlowActivitySession(id: $id) {
      id
      locationId
      providerId
      otherProviderIds
      status
      activityId
      activityType
      waitingCustomerActivities {
        activityId
        activityType
        customerId
        customerName
        customerShortCode
        customerPreferredProviderIds
        strictlyProviders
        stationId
        stationName
        updatedAt
        createdAt
      }
      servingCustomerActivities {
        activityId
        activityType
        customerId
        customerName
        customerShortCode
        customerPreferredProviderIds
        strictlyProviders
        stationId
        stationName
        updatedAt
        createdAt
      }
      completedCustomerActivities {
        activityId
        activityType
        customerId
        customerName
        customerShortCode
        customerPreferredProviderIds
        strictlyProviders
        stationId
        stationName
        updatedAt
        createdAt
      }
      stationIds
    }
  }
`;
export const listFlowActivitySessions = /* GraphQL */ `
  query ListFlowActivitySessions(
    $locationId: ID!
    $startTime: AWSDateTime!
    $endTime: AWSDateTime!
  ) {
    listFlowActivitySessions(
      locationId: $locationId
      startTime: $startTime
      endTime: $endTime
    ) {
      id
      locationId
      providerId
      otherProviderIds
      status
      activityId
      activityType
      waitingCustomerActivities {
        activityId
        activityType
        customerId
        customerName
        customerShortCode
        customerPreferredProviderIds
        strictlyProviders
        stationId
        stationName
        updatedAt
        createdAt
      }
      servingCustomerActivities {
        activityId
        activityType
        customerId
        customerName
        customerShortCode
        customerPreferredProviderIds
        strictlyProviders
        stationId
        stationName
        updatedAt
        createdAt
      }
      completedCustomerActivities {
        activityId
        activityType
        customerId
        customerName
        customerShortCode
        customerPreferredProviderIds
        strictlyProviders
        stationId
        stationName
        updatedAt
        createdAt
      }
      stationIds
    }
  }
`;
export const getAppointmentPlanTemplate = /* GraphQL */ `
  query GetAppointmentPlanTemplate($id: ID!) {
    getAppointmentPlanTemplate(id: $id) {
      id
      name
      description
      planType
      allowedServices {
        id
        name
      }
      allowedClassServices {
        id
        name
      }
      schedule {
        periodType
        periodFrequency
        totalPeriods
      }
      milestones {
        appointmentSequence
        milestone
      }
      totalSessions
      isActive
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const listAppointmentPlanTemplates = /* GraphQL */ `
  query ListAppointmentPlanTemplates(
    $minCreatedTime: AWSDateTime
    $maxCreatedTime: AWSDateTime
  ) {
    listAppointmentPlanTemplates(
      minCreatedTime: $minCreatedTime
      maxCreatedTime: $maxCreatedTime
    ) {
      id
      name
      description
      planType
      allowedServices {
        id
        name
      }
      allowedClassServices {
        id
        name
      }
      schedule {
        periodType
        periodFrequency
        totalPeriods
      }
      milestones {
        appointmentSequence
        milestone
      }
      totalSessions
      isActive
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const getAppointmentPlan = /* GraphQL */ `
  query GetAppointmentPlan($id: ID!) {
    getAppointmentPlan(id: $id) {
      id
      name
      description
      templateId
      customerId
      planType
      startAt
      completedAt
      isActive
      appointmentIds
      appointments {
        id
        patientId
        serviceId
        locationId
        practitionerId
        flowActivityId
        appointmentDate
        appointmentStartTime
        appointmentEndTime
        appointmentCheckInTime
        appointmentCompleteTime
        type
        status
        emailReminderSent
        emailReminderAt
        smsReminderSent
        smsReminderSentAt
        appointmentNote
        confirmedAt
        confirmedBy
        cancellationAt
        cancellationBy
        cancellationNote
        classAppointmentId
        classServiceId
        passId
        patientPassId
        passRedemptionId
        planId
        planMilestone
        onlineBookingTransactionId
        onlineBookingPageId
        onlineBookingCustomFields
        createdAt
        createdBy
        updatedAt
        updatedBy
        voidedAt
        voidedBy
        voidedReason
      }
      completedAppointmentIds
      cancelledAppointmentIds
      missedAppointmentIds
      upcomingAppointmentIds
      totalSessions
      createdAt
      createdBy
      updatedAt
      updatedBy
      version
    }
  }
`;
export const listTemplateAppointmentPlans = /* GraphQL */ `
  query ListTemplateAppointmentPlans(
    $templateId: ID!
    $minCreatedTime: AWSDateTime
    $maxCreatedTime: AWSDateTime
  ) {
    listTemplateAppointmentPlans(
      templateId: $templateId
      minCreatedTime: $minCreatedTime
      maxCreatedTime: $maxCreatedTime
    ) {
      id
      name
      description
      templateId
      customerId
      planType
      startAt
      completedAt
      isActive
      appointmentIds
      appointments {
        id
        patientId
        serviceId
        locationId
        practitionerId
        flowActivityId
        appointmentDate
        appointmentStartTime
        appointmentEndTime
        appointmentCheckInTime
        appointmentCompleteTime
        type
        status
        emailReminderSent
        emailReminderAt
        smsReminderSent
        smsReminderSentAt
        appointmentNote
        confirmedAt
        confirmedBy
        cancellationAt
        cancellationBy
        cancellationNote
        classAppointmentId
        classServiceId
        passId
        patientPassId
        passRedemptionId
        planId
        planMilestone
        onlineBookingTransactionId
        onlineBookingPageId
        onlineBookingCustomFields
        createdAt
        createdBy
        updatedAt
        updatedBy
        voidedAt
        voidedBy
        voidedReason
      }
      completedAppointmentIds
      cancelledAppointmentIds
      missedAppointmentIds
      upcomingAppointmentIds
      totalSessions
      createdAt
      createdBy
      updatedAt
      updatedBy
      version
    }
  }
`;
export const listCustomerAppointmentPlans = /* GraphQL */ `
  query ListCustomerAppointmentPlans(
    $customerId: ID!
    $minStartTime: AWSDateTime
    $maxStartTime: AWSDateTime
  ) {
    listCustomerAppointmentPlans(
      customerId: $customerId
      minStartTime: $minStartTime
      maxStartTime: $maxStartTime
    ) {
      id
      name
      description
      templateId
      customerId
      planType
      startAt
      completedAt
      isActive
      appointmentIds
      appointments {
        id
        patientId
        serviceId
        locationId
        practitionerId
        flowActivityId
        appointmentDate
        appointmentStartTime
        appointmentEndTime
        appointmentCheckInTime
        appointmentCompleteTime
        type
        status
        emailReminderSent
        emailReminderAt
        smsReminderSent
        smsReminderSentAt
        appointmentNote
        confirmedAt
        confirmedBy
        cancellationAt
        cancellationBy
        cancellationNote
        classAppointmentId
        classServiceId
        passId
        patientPassId
        passRedemptionId
        planId
        planMilestone
        onlineBookingTransactionId
        onlineBookingPageId
        onlineBookingCustomFields
        createdAt
        createdBy
        updatedAt
        updatedBy
        voidedAt
        voidedBy
        voidedReason
      }
      completedAppointmentIds
      cancelledAppointmentIds
      missedAppointmentIds
      upcomingAppointmentIds
      totalSessions
      createdAt
      createdBy
      updatedAt
      updatedBy
      version
    }
  }
`;
export const getCreditNote = /* GraphQL */ `
  query GetCreditNote($id: ID!) {
    getCreditNote(id: $id) {
      id
      locationId
      customerId
      customer {
        id
        fullName
        isInitialized
        preferredProviderIds
        strictlyProviders
        customerShortCode
        profilePicture
        quickbooksId
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      invoiceId
      effectiveAt
      refundPaymentId
      items {
        id
        creditNoteId
        invoiceId
        invoiceItemId
        name
        description
        quantity
      }
      reason
      note
      status
      creditNoteNumber
      totalPreTaxAmount {
        amount
        currency
        precision
        scale
      }
      taxRateUsed {
        amount
        currency
        precision
        scale
      }
      taxAmount {
        amount
        currency
        precision
        scale
      }
      totalAmountDue {
        amount
        currency
        precision
        scale
      }
      type
      voidedAt
      voidedBy
      voidedReason
      voidEffectiveAt
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const getCreditNoteByNumber = /* GraphQL */ `
  query GetCreditNoteByNumber($noteNumber: String!) {
    getCreditNoteByNumber(noteNumber: $noteNumber) {
      id
      locationId
      customerId
      customer {
        id
        fullName
        isInitialized
        preferredProviderIds
        strictlyProviders
        customerShortCode
        profilePicture
        quickbooksId
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      invoiceId
      effectiveAt
      refundPaymentId
      items {
        id
        creditNoteId
        invoiceId
        invoiceItemId
        name
        description
        quantity
      }
      reason
      note
      status
      creditNoteNumber
      totalPreTaxAmount {
        amount
        currency
        precision
        scale
      }
      taxRateUsed {
        amount
        currency
        precision
        scale
      }
      taxAmount {
        amount
        currency
        precision
        scale
      }
      totalAmountDue {
        amount
        currency
        precision
        scale
      }
      type
      voidedAt
      voidedBy
      voidedReason
      voidEffectiveAt
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const listCreditNotes = /* GraphQL */ `
  query ListCreditNotes(
    $locationId: ID
    $customerId: ID
    $invoiceId: ID
    $effectiveStartTime: AWSDateTime
    $effectiveEndTime: AWSDateTime
  ) {
    listCreditNotes(
      locationId: $locationId
      customerId: $customerId
      invoiceId: $invoiceId
      effectiveStartTime: $effectiveStartTime
      effectiveEndTime: $effectiveEndTime
    ) {
      id
      locationId
      customerId
      customer {
        id
        fullName
        isInitialized
        preferredProviderIds
        strictlyProviders
        customerShortCode
        profilePicture
        quickbooksId
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      invoiceId
      effectiveAt
      refundPaymentId
      items {
        id
        creditNoteId
        invoiceId
        invoiceItemId
        name
        description
        quantity
      }
      reason
      note
      status
      creditNoteNumber
      totalPreTaxAmount {
        amount
        currency
        precision
        scale
      }
      taxRateUsed {
        amount
        currency
        precision
        scale
      }
      taxAmount {
        amount
        currency
        precision
        scale
      }
      totalAmountDue {
        amount
        currency
        precision
        scale
      }
      type
      voidedAt
      voidedBy
      voidedReason
      voidEffectiveAt
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const getProviderNoteTemplate = /* GraphQL */ `
  query GetProviderNoteTemplate($id: ID!) {
    getProviderNoteTemplate(id: $id) {
      id
      providerId
      templateName
      templateContent
      isGlobal
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const listProviderNoteTemplates = /* GraphQL */ `
  query ListProviderNoteTemplates($providerId: ID!) {
    listProviderNoteTemplates(providerId: $providerId) {
      id
      providerId
      templateName
      templateContent
      isGlobal
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const listGlobalProviderNoteTemplates = /* GraphQL */ `
  query ListGlobalProviderNoteTemplates {
    listGlobalProviderNoteTemplates {
      id
      providerId
      templateName
      templateContent
      isGlobal
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
