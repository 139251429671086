export const listAllInvoicesQuery = /* GraphQL */ `
  query ListInvoices(
    $patientId: ID
    $appointmentId: ID
    $locationIds: [ID]
    $practitionerId: ID
    $corporateId: ID
    $issueStartTime: AWSDateTime
    $issueEndTime: AWSDateTime
    $appointmentIds: [ID!]
    $limit: Int
    $orderBy: ModelSortDirection
    $excludeVoided: Boolean
  ) {
    listInvoices(
      patientId: $patientId
      appointmentId: $appointmentId
      locationIds: $locationIds
      practitionerId: $practitionerId
      corporateId: $corporateId
      issueStartTime: $issueStartTime
      issueEndTime: $issueEndTime
      appointmentIds: $appointmentIds
      limit: $limit
      orderBy: $orderBy
      excludeVoided: $excludeVoided
    ) {
      id
      type
      patientId
      appointmentId
      practitionerId
      locationId
      corporateId
      # appointments {
      #   id
      #   appointmentDate
      # }
      patient {
        id
        fullName
        piiProfile {
          preferredName
          fullName
          email
          identityNumber
          addressStreetAddress
          addressUnitNo
          addressPostalCode
          addressCountry
          employer
        }
        medicalProfile {
          corporates {
            corporateId
            corporate {
              id
              companyName
              code
              category
            }
            paymentSchemeId
            membershipDetails
            isActive
            notes
            start
            expiry
            number
          }
          insurance
          subsidySchemes
        }
      }
      items {
        id
        name
        type
        category
        adjustments {
          amount {
            amount
            currency
            precision
            scale
          }
        }
        # payments {
        #   amountPaid {
        #     amount
        #     currency
        #     precision
        #     scale
        #   }
        #   allocations {
        #     amountAllocated {
        #       amount
        #       currency
        #       precision
        #       scale
        #     }
        #     invoiceItemId
        #   }
        # }
        amountOutstanding {
          amount
          currency
          precision
          scale
        }
        unitSellingPrice {
          amount
          currency
          precision
          scale
        }
        description
        quantity
        reference
        discountCodes
        voidedAt
        voidedBy
        voidedReason
        createdAt
        createdBy
      }
      payments {
        id
        patientId
        invoiceId
        locationId
        type
        mode
        status
        paymentNumber
        paymentTime
        settlementTime
        reference
        isInsurance
        isRefunded
        amountPaid {
          amount
          currency
          precision
          scale
        }
        allocations {
          invoiceItemId
          amountAllocated {
            amount
            currency
            precision
            scale
          }
        }
        voidedAt
        voidedBy
        voidedReason
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      invoiceNumber
      totalPreTaxAmount {
        amount
        currency
        precision
        scale
      }
      taxRateUsed {
        amount
        currency
        precision
        scale
      }
      taxAmount {
        amount
        currency
        precision
        scale
      }
      postTaxAdjustments {
        id
        type
        amount {
          amount
          currency
          precision
          scale
        }
        reference
        voidedAt
        voidedBy
        voidedReason
        createdAt
        createdBy
      }
      totalAmountDue {
        amount
        currency
        precision
        scale
      }
      totalAmountOutstanding {
        amount
        currency
        precision
        scale
      }
      issueDate
      dueDate
      reference
      note
      voidedAt
      voidedBy
      voidedReason
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;

export const customGetInvoiceById = /* GraphQL */ `
  query GetInvoice($id: ID!) {
    getInvoice(id: $id) {
      id
      type
      patientId
      appointmentId
      practitionerId
      locationId
      corporateId
      # appointments {
      #   id
      #   appointmentDate
      # }
      patient {
        id
        fullName
        piiProfile {
          preferredName
          fullName
          email
          identityNumber
          addressStreetAddress
          addressUnitNo
          addressPostalCode
          addressCountry
          employer
        }
        medicalProfile {
          corporates {
            corporateId
            corporate {
              id
              companyName
              code
              category
            }
            paymentSchemeId
            membershipDetails
            isActive
            notes
            start
            expiry
            number
          }
          insurance
          subsidySchemes
        }
      }
      items {
        id
        name
        type
        category
        adjustments {
          amount {
            amount
            currency
            precision
            scale
          }
        }
        # payments {
        #   amountPaid {
        #     amount
        #     currency
        #     precision
        #     scale
        #   }
        #   allocations {
        #     amountAllocated {
        #       amount
        #       currency
        #       precision
        #       scale
        #     }
        #     invoiceItemId
        #   }
        # }
        amountOutstanding {
          amount
          currency
          precision
          scale
        }
        unitSellingPrice {
          amount
          currency
          precision
          scale
        }
        description
        quantity
        reference
        discountCodes
        voidedAt
        voidedBy
        voidedReason
        createdAt
        createdBy
      }
      payments {
        id
        patientId
        invoiceId
        locationId
        type
        mode
        status
        paymentNumber
        paymentTime
        settlementTime
        reference
        isInsurance
        isRefunded
        amountPaid {
          amount
          currency
          precision
          scale
        }
        allocations {
          invoiceItemId
          amountAllocated {
            amount
            currency
            precision
            scale
          }
        }
        voidedAt
        voidedBy
        voidedReason
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      invoiceNumber
      totalPreTaxAmount {
        amount
        currency
        precision
        scale
      }
      taxRateUsed {
        amount
        currency
        precision
        scale
      }
      taxAmount {
        amount
        currency
        precision
        scale
      }
      postTaxAdjustments {
        id
        type
        amount {
          amount
          currency
          precision
          scale
        }
        reference
        voidedAt
        voidedBy
        voidedReason
        createdAt
        createdBy
      }
      totalAmountDue {
        amount
        currency
        precision
        scale
      }
      totalAmountOutstanding {
        amount
        currency
        precision
        scale
      }
      issueDate
      dueDate
      reference
      note
      voidedAt
      voidedBy
      voidedReason
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;

export const customGetInvoiceByNumber = /* GraphQL */ `
  query GetInvoiceByNumber($invoiceNumber: String!) {
    getInvoiceByNumber(invoiceNumber: $invoiceNumber) {
      id
      type
      patientId
      appointmentId
      practitionerId
      locationId
      corporateId
      # appointments {
      #   id
      #   appointmentDate
      # }
      patient {
        id
        fullName
        piiProfile {
          preferredName
          fullName
          email
          identityNumber
          addressStreetAddress
          addressUnitNo
          addressPostalCode
          addressCountry
          employer
        }
        medicalProfile {
          corporates {
            corporateId
            corporate {
              id
              companyName
              code
              category
            }
            paymentSchemeId
            membershipDetails
            isActive
            notes
            start
            expiry
            number
          }
          insurance
          subsidySchemes
        }
      }
      items {
        id
        name
        type
        category
        adjustments {
          amount {
            amount
            currency
            precision
            scale
          }
        }
        # payments {
        #   amountPaid {
        #     amount
        #     currency
        #     precision
        #     scale
        #   }
        #   allocations {
        #     amountAllocated {
        #       amount
        #       currency
        #       precision
        #       scale
        #     }
        #     invoiceItemId
        #   }
        # }
        amountOutstanding {
          amount
          currency
          precision
          scale
        }
        unitSellingPrice {
          amount
          currency
          precision
          scale
        }
        description
        quantity
        reference
        discountCodes
        voidedAt
        voidedBy
        voidedReason
        createdAt
        createdBy
      }
      payments {
        id
        patientId
        invoiceId
        locationId
        type
        mode
        status
        paymentNumber
        paymentTime
        settlementTime
        reference
        isInsurance
        isRefunded
        amountPaid {
          amount
          currency
          precision
          scale
        }
        allocations {
          invoiceItemId
          amountAllocated {
            amount
            currency
            precision
            scale
          }
        }
        voidedAt
        voidedBy
        voidedReason
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      invoiceNumber
      totalPreTaxAmount {
        amount
        currency
        precision
        scale
      }
      taxRateUsed {
        amount
        currency
        precision
        scale
      }
      taxAmount {
        amount
        currency
        precision
        scale
      }
      postTaxAdjustments {
        id
        type
        amount {
          amount
          currency
          precision
          scale
        }
        reference
        voidedAt
        voidedBy
        voidedReason
        createdAt
        createdBy
      }
      totalAmountDue {
        amount
        currency
        precision
        scale
      }
      totalAmountOutstanding {
        amount
        currency
        precision
        scale
      }
      issueDate
      dueDate
      reference
      note
      voidedAt
      voidedBy
      voidedReason
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;

export const listRelatedInvoicesQuery = /* GraphQL */ `
  query ListInvoices(
    $patientId: ID
    $corporateId: ID
    $appointmentId: ID
    $locationIds: [ID]
    $practitionerId: ID
    $issueStartTime: AWSDateTime
    $issueEndTime: AWSDateTime
  ) {
    listInvoices(
      patientId: $patientId
      corporateId: $corporateId
      appointmentId: $appointmentId
      locationIds: $locationIds
      practitionerId: $practitionerId
      issueStartTime: $issueStartTime
      issueEndTime: $issueEndTime
    ) {
      id
      patientId
      appointmentId
      practitionerId
      locationId
      invoiceNumber
    }
  }
`;

export const customListPatientInvoicesQuery = /* GraphQL */ `
  query ListInvoices(
    $patientId: ID
    $appointmentId: ID
    $locationIds: [ID]
    $practitionerId: ID
    $corporateId: ID
    $issueStartTime: AWSDateTime
    $issueEndTime: AWSDateTime
    $appointmentIds: [ID!]
    $limit: Int
    $orderBy: ModelSortDirection
    $excludeVoided: Boolean
  ) {
    listInvoices(
      patientId: $patientId
      appointmentId: $appointmentId
      locationIds: $locationIds
      practitionerId: $practitionerId
      corporateId: $corporateId
      issueStartTime: $issueStartTime
      issueEndTime: $issueEndTime
      appointmentIds: $appointmentIds
      limit: $limit
      orderBy: $orderBy
      excludeVoided: $excludeVoided
    ) {
      id
      type
      patientId
      practitionerId
      locationId
      corporateId
      invoiceNumber
      totalAmountDue {
        amount
        currency
        precision
        scale
      }
      totalAmountOutstanding {
        amount
        currency
        precision
        scale
      }
      issueDate
      dueDate
      voidedAt
      voidedBy
    }
  }
`;

export const customGetInvoice = /* GraphQL */ `
  query GetInvoice($id: ID!) {
    getInvoice(id: $id) {
      id
      type
      patientId
      appointmentId
      practitionerId
      locationId
      corporateId
      # appointments {
      #   id
      #   appointmentDate
      # }
      patient {
        id
        fullName
        piiProfile {
          preferredName
          fullName
          email
          identityNumber
          addressStreetAddress
          addressUnitNo
          addressPostalCode
          addressCountry
          employer
        }
        medicalProfile {
          corporates {
            corporateId
            corporate {
              id
              companyName
              code
              category
            }
            paymentSchemeId
            membershipDetails
            isActive
            notes
            start
            expiry
            number
          }
          insurance
          subsidySchemes
        }
      }
      items {
        id
        name
        type
        category
        adjustments {
          amount {
            amount
            currency
            precision
            scale
          }
        }
        # payments {
        #   amountPaid {
        #     amount
        #     currency
        #     precision
        #     scale
        #   }
        #   allocations {
        #     amountAllocated {
        #       amount
        #       currency
        #       precision
        #       scale
        #     }
        #     invoiceItemId
        #   }
        # }
        amountOutstanding {
          amount
          currency
          precision
          scale
        }
        unitSellingPrice {
          amount
          currency
          precision
          scale
        }
        description
        quantity
        reference
        discountCodes
        voidedAt
        voidedBy
        voidedReason
        createdAt
        createdBy
      }
      payments {
        id
        patientId
        invoiceId
        locationId
        type
        mode
        status
        paymentNumber
        paymentTime
        settlementTime
        reference
        isInsurance
        isRefunded
        amountPaid {
          amount
          currency
          precision
          scale
        }
        allocations {
          invoiceItemId
          amountAllocated {
            amount
            currency
            precision
            scale
          }
        }
        voidedAt
        voidedBy
        voidedReason
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      invoiceNumber
      totalPreTaxAmount {
        amount
        currency
        precision
        scale
      }
      taxRateUsed {
        amount
        currency
        precision
        scale
      }
      taxAmount {
        amount
        currency
        precision
        scale
      }
      postTaxAdjustments {
        id
        type
        amount {
          amount
          currency
          precision
          scale
        }
        reference
        voidedAt
        voidedBy
        voidedReason
        createdAt
        createdBy
      }
      totalAmountDue {
        amount
        currency
        precision
        scale
      }
      totalAmountOutstanding {
        amount
        currency
        precision
        scale
      }
      issueDate
      dueDate
      reference
      note
      voidedAt
      voidedBy
      voidedReason
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;

export const customlistPossibleCustomerAppointmentInvoices = /* GraphQL */ `
  query ListPossibleCustomerAppointmentInvoices(
    $customerId: ID!
    $appointmentId: ID!
  ) {
    listPossibleCustomerAppointmentInvoices(
      customerId: $customerId
      appointmentId: $appointmentId
    ) {
      id
      type
      patientId
      appointmentId
      practitionerId
      locationId
      corporateId
      # appointments {
      #   id
      #   appointmentDate
      # }
      patient {
        id
        fullName
        piiProfile {
          preferredName
          fullName
          email
          identityNumber
          addressStreetAddress
          addressUnitNo
          addressPostalCode
          addressCountry
          employer
        }
        medicalProfile {
          corporates {
            corporateId
            corporate {
              id
              companyName
              code
              category
            }
            paymentSchemeId
            membershipDetails
            isActive
            notes
            start
            expiry
            number
          }
          insurance
          subsidySchemes
        }
      }
      items {
        id
        name
        type
        category
        adjustments {
          amount {
            amount
            currency
            precision
            scale
          }
        }
        # payments {
        #   amountPaid {
        #     amount
        #     currency
        #     precision
        #     scale
        #   }
        #   allocations {
        #     amountAllocated {
        #       amount
        #       currency
        #       precision
        #       scale
        #     }
        #     invoiceItemId
        #   }
        # }
        amountOutstanding {
          amount
          currency
          precision
          scale
        }
        unitSellingPrice {
          amount
          currency
          precision
          scale
        }
        description
        quantity
        reference
        discountCodes
        voidedAt
        voidedBy
        voidedReason
        createdAt
        createdBy
      }
      payments {
        id
        patientId
        invoiceId
        locationId
        type
        mode
        status
        paymentNumber
        paymentTime
        settlementTime
        reference
        isInsurance
        isRefunded
        amountPaid {
          amount
          currency
          precision
          scale
        }
        allocations {
          invoiceItemId
          amountAllocated {
            amount
            currency
            precision
            scale
          }
        }
        voidedAt
        voidedBy
        voidedReason
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      invoiceNumber
      totalPreTaxAmount {
        amount
        currency
        precision
        scale
      }
      taxRateUsed {
        amount
        currency
        precision
        scale
      }
      taxAmount {
        amount
        currency
        precision
        scale
      }
      postTaxAdjustments {
        id
        type
        amount {
          amount
          currency
          precision
          scale
        }
        reference
        voidedAt
        voidedBy
        voidedReason
        createdAt
        createdBy
      }
      totalAmountDue {
        amount
        currency
        precision
        scale
      }
      totalAmountOutstanding {
        amount
        currency
        precision
        scale
      }
      issueDate
      dueDate
      reference
      note
      voidedAt
      voidedBy
      voidedReason
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;

export const customListAppointmentsInvoices = /* GraphQL */ `
  query ListInvoices($appointmentIds: [ID!]) {
    listInvoices(appointmentIds: $appointmentIds) {
      id
      type
      patientId
      appointmentId
      practitionerId
      locationId
      corporateId
      invoiceNumber
      # totalAmountDue {
      #  amount
      #  currency
      #  precision
      #  scale
      # }
      totalAmountOutstanding {
        amount
        currency
        precision
        scale
      }
      issueDate
      dueDate
      reference
      note
      voidedAt
      voidedBy
      voidedReason
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;

export const customListShortInvoicesInDateRange = /* GraphQL */ `
  query ListInvoices(
    $patientId: ID
    $corporateId: ID
    $appointmentId: ID
    $locationIds: [ID]
    $practitionerId: ID
    $issueStartTime: AWSDateTime
    $issueEndTime: AWSDateTime
  ) {
    listInvoices(
      patientId: $patientId
      corporateId: $corporateId
      appointmentId: $appointmentId
      locationIds: $locationIds
      practitionerId: $practitionerId
      issueStartTime: $issueStartTime
      issueEndTime: $issueEndTime
    ) {
      id
      type
      patientId
      appointmentId
      practitionerId
      locationId
      corporateId
      patient {
        id
        fullName
      }
      invoiceNumber
      totalPreTaxAmount {
        amount
        currency
        precision
        scale
      }
      taxRateUsed {
        amount
        currency
        precision
        scale
      }
      taxAmount {
        amount
        currency
        precision
        scale
      }
      postTaxAdjustments {
        id
        type
        amount {
          amount
          currency
          precision
          scale
        }
        reference
        voidedAt
        voidedBy
        voidedReason
        createdAt
        createdBy
      }
      totalAmountDue {
        amount
        currency
        precision
        scale
      }
      totalAmountOutstanding {
        amount
        currency
        precision
        scale
      }
      issueDate
      dueDate
      # reference
      # note
      voidedAt
      voidedBy
      # voidedReason
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
